import { useMemo } from "react";
import {
  blue200,
  blue300,
  neutral100,
  neutral200,
  neutral300,
  yellow200,
  yellow300,
} from "./BreakdownPerStage";

export const useImpactColors = (): Record<string, string> => {
  return useMemo(
    () => ({
      "GWP-total": yellow300,
      ODP: yellow200,
      AP: blue300,
      "EP-freshwater": blue200,
      POCP: neutral100,
      ADPE: neutral200,
      ADPF: neutral300,
      WDP: blue300,

      PM: neutral100,
      PERT: neutral200,
      FW: neutral300,
    }),
    [],
  );
};

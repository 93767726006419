import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect } from "react";

export const Logout = () => {
  const { logout } = useAuth0();
  const logoutWithRedirect = useCallback(() => {
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  }, [logout]);

  useEffect(() => {
    logoutWithRedirect();
  }, [logoutWithRedirect]);

  return null;
};

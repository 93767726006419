import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { usePatchPlant, usePostPlant } from "../../api/endpoints/plants";
import { useActiveManufacturer } from "../../state/manufacturers";
import { useActivePlantControl, usePlants } from "../../state/plants";
import { showErrorToast } from "../../util/toasts";
import { ManagePlantFieldsT } from "../company-settings/ManagePlantFields";

export const usePlantForm = ({
  isEdit,
  onCreateSuccess,
}: {
  isEdit?: boolean;
  onCreateSuccess?: () => void;
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { activeManufacturer } = useActiveManufacturer();
  const { activePlant, setActivePlantId } = useActivePlantControl();

  const methods = useForm<ManagePlantFieldsT>({
    defaultValues: {
      name: isEdit ? activePlant?.name : undefined,
      city: isEdit ? activePlant?.city : undefined,
      country: isEdit ? activePlant?.country : undefined,
      product_categories: isEdit ? activePlant?.product_categories || [] : [],
    },
  });

  useEffect(
    function populatePlant() {
      if (!isEdit || !activePlant) return;
      methods.reset({
        name: activePlant.name,
        city: activePlant.city,
        country: activePlant.country,
        product_categories: activePlant.product_categories,
      });
    },
    [methods, activePlant, isEdit],
  );

  const { t } = useTranslation();
  const { data: plants } = usePlants();
  const { mutate: postPlant, isPending: postPending } = usePostPlant();
  const { mutate: patchPlant, isPending: patchPending } = usePatchPlant();

  const onSubmit: SubmitHandler<ManagePlantFieldsT> = (data) => {
    if (isEdit && activePlant) {
      patchPlant(
        {
          plantId: activePlant.id,
          plant: {
            ...activePlant,
            ...data,
          },
        },
        {
          onSuccess: (result) => {
            setActivePlantId(result.id);
            navigate("/company-settings");
          },
          onError: () => {
            showErrorToast(t("We can't remove categories when they're still in use"));
          },
        },
      );
    } else if (!isEdit) {
      postPlant(
        {
          plant: {
            ...data,
            manufacturer_id: activeManufacturer.id,
            electricity_mix: null,
            waste_treatment: {},
          },
        },
        {
          onSuccess: (result) => {
            queryClient.setQueryData([activeManufacturer.id, "plants"], [...plants, result]);
            setActivePlantId(result.id);
            navigate("/onboarding");
            onCreateSuccess?.();
          },
        },
      );
    }
  };

  return {
    methods,
    activePlant,
    loading: patchPending || postPending,
    onSubmit,
  };
};

import { cva, VariantProps } from "class-variance-authority";
import { ReactNode } from "react";

export const styles = cva(
  [
    "shrink-0",
    "inline-flex items-center justify-center",
    "uppercase",
    "font-bold",
    "whitespace-nowrap",
  ],
  {
    variants: {
      size: {
        sm: "text-xs py-2 px-1 h-6",
        md: "text-base py-3 px-2 h-10",
        lg: "text-xl py-3 px-3 h-12",
      },
      color: {
        white: "bg-white text-neutral-500",
        neutral: "bg-neutral-100 text-neutral-500",
        gray: "bg-neutral-200 text-neutral-500",
        yellow: "bg-yellow-200 text-yellow-500",
        brown: "bg-co2brown text-builtgreen",
        brownLight: "bg-co2light text-builtgreen",
      },
    },
    defaultVariants: {
      size: "md",
      color: "neutral",
    },
  },
);

export const Badge = ({
  children,
  size,
  color,
}: { children: ReactNode } & VariantProps<typeof styles>) => (
  <div className={styles({ size, color })}>{children}</div>
);

import { cva } from "class-variance-authority";
import { OverlayArrow as OverlayArrowPrimitive } from "react-aria-components";

const styles = cva(["stroke-slate-200", "fill-white", "h-3", "w-3"])();

export const OverlayArrow = () => (
  <OverlayArrowPrimitive
    className={({ placement }) => {
      if (placement === "top") return "[&>svg]:rotate-0";
      if (placement === "left") return "[&>svg]:-rotate-90";
      if (placement === "bottom") return "[&>svg]:rotate-180";
      return "[&>svg]:rotate-90";
    }}
  >
    <svg className={styles}>
      <path d="M0 0,L6 6,L12 0" />
    </svg>
  </OverlayArrowPrimitive>
);

import { useTranslation } from "react-i18next";
import { Plant, ProductCategory } from "../../api/types";
import { Heading4 } from "../../components/Typography";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { TextFieldConnected } from "../../form-components/TextFieldConnected";
import { COUNTRY_OPTIONS } from "../../util/countries";
import { CustomProductCategoryPicker } from "./CustomProductCategoryPicker";

export interface ManagePlantFieldsT {
  name: string;
  city: string;
  country: string;
  product_categories: ProductCategory["id"][];
}

export const ManagePlantFields = ({ activePlant }: { activePlant?: Plant }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center max-w-6xl mx-auto gap-8 pt-20">
      <div className="text-center">
        <Heading4>{t("Plant Setup")}</Heading4>
      </div>
      <div className="grid grid-cols-3 gap-6">
        <TextFieldConnected name="name" label={t("Plant Name")} isRequired />
        <TextFieldConnected name="city" label={t("City")} isRequired />
        <ComboBoxFieldConnected
          name="country"
          isRequired
          options={COUNTRY_OPTIONS}
          label={t("Country")}
        />
      </div>
      <CustomProductCategoryPicker activePlant={activePlant} />
    </div>
  );
};

import { useTranslation } from "react-i18next";
import { EndOfScale, ImpactSummary, Product } from "../../api/types";
import { Label, Text14 } from "../../components/Typography";
import { coreIndicators, filterImpact } from "../../lib/impact";
import { ImpactFactors } from "./ImpactFactors";
import { RadarChart } from "./RadarChart";

export const EnvironmentalPerformanceIndicators = ({
  impactSummary,
  endsOfScale,
  product,
}: {
  impactSummary: ImpactSummary;
  endsOfScale: EndOfScale[];
  product: Product;
}) => {
  const { t } = useTranslation();

  const filteredImpacts = filterImpact(impactSummary.a1_a3, coreIndicators);

  return (
    <div className="flex flex-col gap-3">
      <Label className="flex items-center gap-2">{t("Environmental Performance Indicators")}</Label>
      <div className="grid grid-cols-1 md:grid-cols-[1fr_1.681fr_1fr] gap-8">
        <Text14>
          <div
            dangerouslySetInnerHTML={{
              __html: t(
                "While GWP assesses the contribution of a product or process to climate change by measuring the greenhouse gases it emits, other significant environmental impacts may be overlooked if only GWP is considered.<br /><br />A product with low GWP might still <strong>cause significant harm</strong> for <strong>overall planetary health</strong>, like water depletion and soil accidification.<br /><br />By assessing multiple indicators,  gain a <strong>holistic understanding</strong> of a product's overall environmental footprint, avoid unintended trade-offs, and support better decision-making for long-term sustainability.<br /><br />The diagram covers the <strong>A1-A3 stages</strong> of the lifecycle.",
              ),
            }}
          />
        </Text14>
        <div className="h-[500px]">
          <RadarChart impacts={filteredImpacts} endsOfScale={endsOfScale} product={product} />
        </div>
        <div className="flex items-center">
          <ImpactFactors impacts={filteredImpacts} />
        </div>
      </div>
    </div>
  );
};

import { cva, VariantProps } from "class-variance-authority";
import { ReactNode } from "react";
import { Label14 } from "./Typography";

const styles = cva("mt-1", {
  variants: {
    intent: {
      text: "text-builtgreen",
      error: "text-red-500",
    },
  },
  defaultVariants: {
    intent: "text",
  },
});

export const FieldText = ({
  children,
  intent,
}: VariantProps<typeof styles> & { children: ReactNode }) => {
  return <Label14 className={styles({ intent })}>{children}</Label14>;
};

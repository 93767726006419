import { CheckCircle, Error } from "@mui/icons-material";
import { ReactNode } from "react";
import { toast } from "react-toastify";

// NOT USING THIS YET, BUT WE MIGHT SOON
// export const showInfoToast = (content: ReactNode) => {
//   toast(content, { autoClose: 6000, type: "info", icon: <Info className="text-steelblue" /> });
// };

export const showSuccessToast = (content: ReactNode) => {
  toast(content, {
    autoClose: 6000,
    type: "success",
    icon: (
      // Have to wrap this in a fragment right now
      // see: https://github.com/fkhadra/react-toastify/issues/1151#issuecomment-2316405005
      <>
        <CheckCircle className="text-green-500" />
      </>
    ),
  });
};

// NOT USING THIS YET, BUT WE MIGHT SOON
// export const showWarningToast = (content: ReactNode) => {
//   toast(content, {
//     autoClose: false,
//     type: "warning",
//     icon: <WarningAmber className="text-amber-500" />,
//   });
// };

export const showErrorToast = (content: ReactNode) => {
  toast(content, {
    autoClose: false,
    type: "error",
    icon: (
      // Have to wrap this in a fragment right now
      // see: https://github.com/fkhadra/react-toastify/issues/1151#issuecomment-2316405005
      <>
        <Error className="text-red-500" />
      </>
    ),
  });
};

import { Product } from "../../api/types";
import { Label, Text14 } from "../../components/Typography";

export const TechSpecs = ({ product }: { product: Product }) => {
  return (
    <>
      {product.tech_specs && Object.keys(product.tech_specs).length > 0 && (
        <>
          <div className="grid grid-cols-1 gap-2 max-w-2xl">
            {Object.entries(product.tech_specs).map(([name, value]) => (
              <div key={name}>
                <Text14>{product.tech_specs_schema.properties[name]?.title}</Text14>
                <Label>
                  {value} {product.tech_specs_schema.properties[name]?.unit}
                </Label>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

import { DownloadOutlined } from "@mui/icons-material";
import { useMemo } from "react";
import { DialogTrigger } from "react-aria-components";
import { FormProvider, SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useProductionProcesses } from "../../api/endpoints/production-processes";
import { Button } from "../../components/Button";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../components/Modal";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { useElementaries } from "../../state/elementaries";
import { useActivePlant } from "../../state/plants";
import { useDownloadProductsBulkUploadSheet } from "../../state/products";

export const DownloadProductsTemplate = () => {
  const { t } = useTranslation();

  return (
    <DialogTrigger>
      <Button>
        <DownloadOutlined />
        {t("Download template")}
      </Button>
      <DownloadProductsTemplateModal />
    </DialogTrigger>
  );
};

interface Form {
  categoryName: string;
  elementary_id: string;
}

const DownloadProductsTemplateModal = () => {
  const { t } = useTranslation();
  const { mutate: download } = useDownloadProductsBulkUploadSheet();

  const methods = useForm<Form>();

  const onSubmit: SubmitHandler<Form> = ({ elementary_id }) => {
    download(elementary_id);
  };

  return (
    <Modal>
      {({ close }) => (
        <>
          <ModalHeader title={t("Download template")} onClose={close} />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
              <ModalBody>
                <DownloadProductsTemplateForm />
              </ModalBody>
              <ModalFooter>
                <Button intent="secondary" type="button" onPress={close}>
                  {t("Cancel")}
                </Button>
                <Button type="submit">{t("Download")}</Button>
              </ModalFooter>
            </form>
          </FormProvider>
        </>
      )}
    </Modal>
  );
};

const DownloadProductsTemplateForm = () => {
  const { t } = useTranslation();
  const activePlant = useActivePlant();
  const { elementaries } = useElementaries();
  const { data: productionProcesses } = useProductionProcesses({ plantId: activePlant.id });

  const { watch } = useFormContext<Form>();
  const category = watch("categoryName");

  const plantProducesElementaries = useMemo(() => {
    return elementaries.filter((e) =>
      productionProcesses.some((pp) => pp.elementary_ids.includes(e.id)),
    );
  }, [elementaries, productionProcesses]);

  const categoryOptions = useMemo(() => {
    const uniqueOptions = new Set(plantProducesElementaries.map((e) => e.category));
    return Array.from(uniqueOptions)
      .sort((a, b) => a.localeCompare(b))
      .map((category) => ({
        id: category,
        label: category,
      }));
  }, [plantProducesElementaries]);

  const materialOptions = useMemo(() => {
    return elementaries
      .filter(
        (e) =>
          e.active &&
          e.category === category &&
          plantProducesElementaries.some((x) => x.id === e.id),
      )
      .map((e) => ({
        id: e.id,
        label: e.name_en,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
  }, [elementaries, category, plantProducesElementaries]);

  return (
    <div className="flex flex-col gap-4">
      <ComboBoxFieldConnected
        name="categoryName"
        options={categoryOptions}
        label={t("Category")}
        isRequired
      />
      <ComboBoxFieldConnected
        name="elementary_id"
        label={t("Material")}
        isRequired
        options={materialOptions}
        isDisabled={!category}
      />
    </div>
  );
};

import { cva, VariantProps } from "class-variance-authority";
import { ReactNode } from "react";
import {
  MenuItem as MenuItemPrimitive,
  Menu as MenuPrimitive,
  Popover as PopoverPrimitive,
  PopoverProps,
  type MenuItemProps,
} from "react-aria-components";
import { Label16, Text14 } from "./Typography";

const menuItemStyles = cva(
  [
    "border-b",
    "border-neutral-200",
    "last-of-type:border-b-0",
    "pl-4",
    "pr-6",
    "bg-white",
    "hover:bg-neutral-200",
    "outline-none",
    "max-w-sm",
    "space-y-2",
    "focus:bg-neutral-200",
  ],
  {
    variants: {
      isDisabled: {
        true: "opacity-50 cursor-not-allowed",
        false: "cursor-pointer",
      },
      size: {
        sm: "text-sm py-2",
        md: "text-base py-4",
      },
    },
    defaultVariants: {
      size: "md",
      isDisabled: false,
    },
  },
);

export const MenuItem = ({
  icon,
  text,
  description,
  isDisabled,
  size,
  ...props
}: Omit<MenuItemProps, "className"> & {
  icon?: ReactNode;
  text: string;
  description?: string;
} & VariantProps<typeof menuItemStyles>) => {
  return (
    <MenuItemPrimitive
      {...props}
      className={menuItemStyles({ isDisabled: !!isDisabled, size })}
      isDisabled={isDisabled}
    >
      <span slot="label" className="flex items-center gap-3">
        {icon && <span className="text-builtgreen">{icon}</span>}
        <Label16 slot="text">{text}</Label16>
      </span>
      {description && <Text14 slot="description">{description}</Text14>}
    </MenuItemPrimitive>
  );
};

const popoverStyles = cva([
  "bg-white",
  "max-w-xl",
  "border",
  "shadow-lg",
  "flex",
  "flex-col",
  "rounded-lg",
  "overflow-y-auto",
  "data-[entering]:animate-in",
  "data-[entering]:fade-in",
  "data-[entering]:zoom-in-95",
  "data-[entering]:slide-in-from-top-4",
  "data-[exiting]:animate-out",
  "data-[exiting]:fade-out",
  "data-[exiting]:zoom-out-95",
  "data-[exiting]:slide-out-to-top-4",
  "fill-mode-forwards",
])();

export const Menu = ({
  children,
  placement,
}: { children: ReactNode } & Pick<PopoverProps, "placement">) => {
  return (
    <PopoverPrimitive className={popoverStyles} placement={placement}>
      <MenuPrimitive className="outline-none">{children}</MenuPrimitive>
    </PopoverPrimitive>
  );
};

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";

export type ConcreteScenario = {
  application: ReturnType<typeof useConcreteApplicationOptions>[number]["id"];
};

const useConcreteApplicationOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { label: t("Engineering structure - Exposed to rain"), id: "STRUCTURE_EXPOSED" as const },
      {
        label: t("Engineering structure - Protected from rain"),
        id: "STRUCTURE_PROTECTED" as const,
      },
      { label: t("Engineering structure - In the ground"), id: "STRUCTURE_GROUND" as const },
      { label: t("Building - Outside - Exposed to rain"), id: "BUILDING_OUTSIDE_EXPOSED" as const },
      {
        label: t("Building - Outside - Protected from rain"),
        id: "BUILDING_OUTSIDE_PROTECTED" as const,
      },
      {
        label: t("Building - Dry interior - With paint / wallpaper"),
        id: "BUILDING_DRY_PAINT" as const,
      },
      {
        label: t("Building - Dry interior - Without paint / wallpaper"),
        id: "BUILDING_DRY_NOPAINT" as const,
      },
      { label: t("Building - In the ground"), id: "BUILDING_GROUND" as const },
    ],
    [t],
  );
};

export const ConcreteApplicationField = () => {
  const { t } = useTranslation();

  return (
    <ComboBoxFieldConnected
      name="scenario.application"
      label={t("Application Scenario")}
      isRequired
      options={useConcreteApplicationOptions()}
    />
  );
};

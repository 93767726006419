import { useSuspenseQuery } from "@tanstack/react-query";
import { useGetProductMetadata } from "../api/endpoints/product-metadata";
import { ProductMetadata } from "../api/types";

export const useProductMetadata = () => {
  const getProductMetadata = useGetProductMetadata();

  return useSuspenseQuery({
    queryKey: ["productMetadata"],
    queryFn: getProductMetadata,
    select: (productMetadata: ProductMetadata[]) => {
      return {
        productMetadata,
        productMetadataMap: Object.fromEntries(
          productMetadata.map((metadata) => [metadata.id, metadata]),
        ),
      };
    },
  });
};

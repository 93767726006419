import { ComponentProps, forwardRef, HTMLProps } from "react";
import { TextField as TextFieldPrimitive, type TextFieldProps } from "react-aria-components";

import { FieldLabel } from "./FieldLabel";
import { FieldText } from "./FieldText";
import { TextArea } from "./TextArea";

export const TextAreaField = forwardRef<
  HTMLTextAreaElement,
  Omit<TextFieldProps, "children" | "className"> & {
    label?: string;
    labelProps?: ComponentProps<typeof FieldLabel>;
    errorMessage?: string;
    inputProps?: Omit<HTMLProps<HTMLTextAreaElement>, "children" | "className">;
    placeholder?: string;
    isRequired?: boolean;
  }
>(({ label, errorMessage, inputProps, labelProps, ...props }, ref) => {
  return (
    <TextFieldPrimitive {...props}>
      {label && (
        <FieldLabel isRequired={props.isRequired} isDisabled={props.isDisabled} {...labelProps}>
          {label}
        </FieldLabel>
      )}
      <TextArea ref={ref} {...inputProps} isDisabled={props.isDisabled} hasError={!!errorMessage} />
      {errorMessage && <FieldText intent="error">{errorMessage}</FieldText>}
    </TextFieldPrimitive>
  );
});

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Impact } from "../api/types";

export const mergeImpact = (impacts: Impact[][]): Impact[] => {
  const merged: Record<string, Impact> = {};
  const flattenedImpacts = impacts.flat();

  for (const impact of flattenedImpacts) {
    const existing = merged[impact?.indicator_name];

    if (!existing) {
      merged[impact.indicator_name] = { ...impact };
      continue;
    }

    existing.value += impact.value;

    if (existing.unit === impact.unit) continue;

    console.warn(
      `Unit mismatch for indicator ${impact.indicator_name}, ${existing.unit} !== ${impact.unit}`,
    );
  }

  return Object.values(merged);
};

export const useImpactByIndicator = (
  impacts: Impact[],
  indicator: Impact["indicator_name"],
): Impact => {
  return useMemo(() => {
    const impact = impacts.find((item) => item.indicator_name === indicator);

    if (impact) return impact;

    console.warn(`Indicator ${indicator} not found in impact array`);

    return {
      indicator_name: indicator,
      value: 0,
      unit: "",
    };
  }, [impacts, indicator]);
};

export const coreIndicators: Set<Impact["indicator_name"]> = new Set([
  "GWP-total",
  "AP",
  "POCP",
  "ADPE",
  "ADPF",
  "EP-freshwater",
  "WDP",
  "ODP",
]);

export const filterImpact = (
  impacts: Impact[],
  indicators: Set<Impact["indicator_name"]>,
): Impact[] => {
  return impacts.filter((impact) => indicators.has(impact.indicator_name));
};

export const useNiceNamePerImpactName = () => {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      "GWP-total": t("Global Warming"),
      ODP: t("Ozone Depletion"),
      AP: t("Water & Soil Acidification"),
      "EP-freshwater": t("Algae Bloom"),
      POCP: t("Smog Formation"),
      ADPE: t("Metals & Minerals Depletion"),
      ADPF: t("Fossil Fuels Depletion"),
      WDP: t("Water Depletion"),
    }),
    [t],
  );
};

export const useTooltipPerImpactName = () => {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      "GWP-total": {
        caption: t("Global Warming (GWP)"),
        description: t(
          "Contribution of the product to climate change through the greenhouse gases it releases across its life cycle.",
        ),
      },
      ODP: {
        caption: t("Ozone Depletion (ODP)"),
        description: t(
          "Contribution of the product to the thinning of the ozone layer, which protects the Earth from harmful ultraviolet radiation.",
        ),
      },
      AP: {
        caption: t("Water & Soil Acidification (AP)"),
        description: t(
          "Potential of the product's life cycle to release pollutants that can lead to harmful acid rain and soil acidification.",
        ),
      },
      POCP: {
        caption: t("Smog Formation (POCP)"),
        description: t(
          "Contribution of the product to the formation of ground-level ozone during its life cycle.",
        ),
      },
      ADPE: {
        caption: t("Metals & Minerals Depletion (ADPe)"),
        description: t(
          "Impact of the product on the depletion of minerals and metals, over its life cycle.",
        ),
      },
      ADPF: {
        caption: t("Fossil Fuels Depletion (ADPf)"),
        description: t(
          "Impact of the product on the depletion of fossil fuels, over its life cycle.",
        ),
      },
      WDP: {
        caption: t("Water Depletion (WDP)"),
        description: t(
          "Impact of the product's lifecycle on the long-term availability of water in natural reservoirs like rivers, lakes, or groundwater.",
        ),
      },
      "EP-freshwater": {
        caption: t("Algae Bloom (EP)"),
        description: t(
          "Potential of the product's life cycle to cause excessive growth of algae in water bodies, which can harm aquatic life and water quality.",
        ),
      },
    }),
    [t],
  );
};

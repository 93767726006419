import { ResponsiveRadar } from "@nivo/radar";
import { EndOfScale, Impact, Product } from "../../api/types";
import { tooltipStyles } from "../../components/Tooltip";
import { Text14 } from "../../components/Typography";
import { useNiceNamePerImpactName, useTooltipPerImpactName } from "../../lib/impact";
import { formatPrecision } from "../../util/format";
import useTailwind from "../../util/useTailwind";

export const RadarChart = ({
  impacts,
  impacts2,
  product,
  product2,
  endsOfScale,
}: {
  impacts: Impact[];
  impacts2?: Impact[];
  product: Product;
  product2?: Product;
  endsOfScale: EndOfScale[];
}) => {
  const tailwind = useTailwind();

  const yellow300 = tailwind.theme.colors.energyyellow;
  const neutral300 = tailwind.theme.colors.neutral[300];
  const co2brown = tailwind.theme.colors.co2brown;

  const tooltipPerImpactName = useTooltipPerImpactName();
  const niceNamePerImpactName = useNiceNamePerImpactName();

  const normalizedImpacts = impacts.map((impact, index) => {
    const maxValue = endsOfScale.find(
      ({ indicator_name }) => impact.indicator_name === indicator_name,
    )?.value;

    const impact2 = impacts2?.[index];

    return {
      ...impact,
      niceName: niceNamePerImpactName[impact.indicator_name as keyof typeof niceNamePerImpactName],
      impact2: maxValue && impact2 ? Math.min(impact2.value / maxValue, 1) : undefined,
      impact1: maxValue ? Math.min(impact.value / maxValue, 1) : 0,
    };
  });

  // We're seeing console.errors when the animation runs.
  // A solution is to set animate={false}, but then we'd lose the animation
  // so since the chart isn't crashing, we're leaving this as is for now
  // TODO: double-check this issue and see if it's been resolved:
  // https://github.com/plouc/nivo/issues/2111

  return (
    <ResponsiveRadar
      data={normalizedImpacts}
      keys={[...(impacts2 ? ["impact2"] : []), "impact1"]}
      indexBy="indicator_name"
      blendMode="multiply"
      gridShape="linear"
      fillOpacity={0.7}
      dotBorderWidth={1}
      dotBorderColor="#404D3E"
      dotColor={{ theme: "background" }}
      valueFormat=">-.3e"
      maxValue={1}
      sliceTooltip={({ index }) => {
        const impact = impacts.find((i) => i.indicator_name === index);
        const impact2 = impacts2?.find((i) => i.indicator_name === index);

        return (
          <div className={tooltipStyles()}>
            <div className="space-y-2">
              {impact && (
                <div>
                  <Text14 className={`break-all`}>{!!impact2 && `${product.name}: `}</Text14>
                  <Text14>
                    <strong>
                      {formatPrecision(impact.value, 4)} {impact.unit}
                    </strong>
                  </Text14>
                </div>
              )}
              {impact2 && product2 && (
                <div>
                  <Text14 className="break-all text-neutral-500">{product2?.name}: </Text14>
                  <Text14>
                    <strong>
                      {formatPrecision(impact2.value, 4)} {impact2.unit}
                    </strong>
                  </Text14>
                </div>
              )}
            </div>
            <hr className="my-2 -mx-4 px-4" />
            <div className="space-y-2">
              <Text14>
                <strong>
                  {tooltipPerImpactName[index as keyof typeof tooltipPerImpactName]?.caption}
                </strong>
              </Text14>
              <Text14>
                {tooltipPerImpactName[index as keyof typeof tooltipPerImpactName]?.description}
              </Text14>
            </div>
          </div>
        );
      }}
      margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
      borderColor={{ from: "color" }}
      gridLabelOffset={20}
      dotSize={4}
      colors={impacts2 ? [neutral300, co2brown] : [yellow300]}
      gridLabel={({ id, x, y }) => {
        const impact = normalizedImpacts.find((i) => i.indicator_name === id);
        return (
          <foreignObject x={x - 70} y={y - 30} width={140} height={60}>
            <span className="text-xs w-full text-center break-words flex items-center justify-center h-full">
              {impact?.niceName || id}
            </span>
          </foreignObject>
        );
      }}
    />
  );
};

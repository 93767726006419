export const COUNTRY_OPTIONS = [
  { label: "Austria", id: "AT" },
  { label: "Belgium", id: "BE" },
  { label: "Bulgaria", id: "BG" },
  { label: "China", id: "CN" },
  { label: "Croatia", id: "HR" },
  { label: "Cyprus", id: "CY" },
  { label: "Czech Republic", id: "CZ" },
  { label: "Denmark", id: "DK" },
  { label: "Estonia", id: "EE" },
  { label: "Finland", id: "FI" },
  { label: "France", id: "FR" },
  { label: "Germany", id: "DE" },
  { label: "Greece", id: "GR" },
  { label: "Hungary", id: "HU" },
  { label: "India", id: "IN" },
  { label: "Ireland", id: "IE" },
  { label: "Italy", id: "IT" },
  { label: "Japan", id: "JP" },
  { label: "Latvia", id: "LV" },
  { label: "Lithuania", id: "LT" },
  { label: "Luxembourg", id: "LU" },
  { label: "Malta", id: "MT" },
  { label: "Netherlands", id: "NL" },
  { label: "Norway", id: "NO" },
  { label: "Poland", id: "PL" },
  { label: "Portugal", id: "PT" },
  { label: "Romania", id: "RO" },
  { label: "Slovakia", id: "SK" },
  { label: "Slovenia", id: "SI" },
  { label: "Spain", id: "ES" },
  { label: "Sweden", id: "SE" },
  { label: "Switzerland", id: "CH" },
  { label: "Turkey", id: "TR" },
  { label: "USA", id: "US" },
  { label: "United Kingdom", id: "GB" },
].sort((a, b) => a.label.localeCompare(b.label));

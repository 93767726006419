import { ImageOutlined, UploadFileOutlined } from "@mui/icons-material";
import { Button as ButtonPrimitive, FileTrigger } from "react-aria-components";
import { Product } from "../../api/types";

import { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import { useImage, useUploadImage } from "../../api/endpoints/manufacturers";
import { apiLink } from "../../api/useApi";
import { useActiveManufacturer } from "../../state/manufacturers";
import { useActivePlant } from "../../state/plants";
import { useUpdateProduct } from "../../state/products";
import { showErrorToast } from "../../util/toasts";
import { useValidateFileSize } from "../../util/validateFileSize";

const SxButton = tw(ButtonPrimitive)`
  w-full
  h-full
  z-20
  absolute
  bg-neutral-900
  bg-opacity-40
  top-0
  left-0
  opacity-0
  group-hover:opacity-100
  transition-opacity
  cursor-pointer
  text-white
  flex
  items-center
  justify-center
`;

export const ProductImage = memo(
  ({ product, disableUpload = false }: { product: Product; disableUpload?: boolean }) => {
    const { t } = useTranslation();
    const { activeManufacturer } = useActiveManufacturer();
    const activePlant = useActivePlant();

    const uploadImage = useUploadImage();
    const { mutate: updateProduct } = useUpdateProduct();

    const getImageAccessLink = useImage();
    const validateFileSize = useValidateFileSize();

    const [imageSrc, setImageSrc] = useState<string | null>(null);

    const handleFileDrop = async (files: FileList | null) => {
      const file = files?.[0];

      if (!file) return;

      try {
        validateFileSize(file);
      } catch (_) {
        return;
      }

      try {
        const key = await uploadImage({
          manufacturerId: activeManufacturer.id,
          file,
        });
        updateProduct({
          productId: product.id,
          product: { ...product, image_url: key },
        });
      } catch (_) {
        showErrorToast(t("Couldn't upload product image."));
      }
    };

    const defaultImage = useMemo(
      () => apiLink(`/static${product.metadata.img_url}`),
      [product.metadata.img_url],
    );

    useEffect(() => {
      const getImageSrc = async () => {
        if (!product?.image_url) {
          setImageSrc(defaultImage);
        } else {
          try {
            const img = await getImageAccessLink({
              manufacturerId: activeManufacturer.id,
              path: product.image_url,
            });
            setImageSrc(img);
          } catch (error) {
            console.error("Error generating image url:", error);
          }
        }
      };
      getImageSrc();
    }, [activeManufacturer, activePlant, product, getImageAccessLink, defaultImage]);

    // Note: we're using the padding-trick to keep the image square, responsively
    return (
      <div className="shrink-0 p-[2px] bg-white relative group w-full pb-[100%]">
        {!imageSrc && (
          <div className="absolute left-0 top-0 w-full h-full flex items-center justify-center">
            <ImageOutlined className="text-neutral-300" fontSize="large" />
          </div>
        )}
        {imageSrc && (
          <img
            className="w-full h-full absolute left-0 top-0 z-10 object-cover select-none pointer-events-none"
            src={imageSrc}
            alt="Product"
          />
        )}
        {!disableUpload && (
          <FileTrigger onSelect={handleFileDrop}>
            <SxButton>
              <UploadFileOutlined fontSize="large" />
            </SxButton>
          </FileTrigger>
        )}
      </div>
    );
  },
);

import { useCallback } from "react";

// Since we're storing some app state in the URL as search parameters
// we need a way to use react-router's <Link> component and preserve
// the search parameters. This hook will take a path and an optional
// object of params and return a string that can be used as the "to"
// prop of a <Link> component.
export const useGetLinkWithParams = () => {
  return useCallback((to: string, params?: Record<string, string>) => {
    const search = new URLSearchParams(document.location.search);

    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        search.set(key, value);
      });
    }

    return `${to}?${search.toString()}`;
  }, []);
};

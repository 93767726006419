import { CloseOutlined, SearchOutlined } from "@mui/icons-material";
import { cva } from "class-variance-authority";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import useDebounce from "react-use/lib/useDebounce";
import { Button } from "../../components/Button";
import { TextField } from "../../components/TextField";

// TODO: Consider making this a variant of the Input component
const bespokeSearchStyles = cva([
  "[&>div]:border",
  "[&>div]:border-neutral-300",
  "[&>div]:rounded-lg",
  "[&>div]:px-2",
  "[&>div]:h-10",
  "[&>div>div]:border-none",
  "[&>div>div]:h-10",
])();

export const SearchControls = memo(function SearchControlsComponent({
  search,
  setSearch,
  placeholder,
}: {
  search: string;
  setSearch: (search: string) => void;
  placeholder?: string;
}) {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState(search);
  useDebounce(
    () => {
      setSearch(searchInput);
    },
    200,
    [searchInput],
  );

  return (
    <div className={bespokeSearchStyles}>
      <TextField
        aria-label={t("Search")}
        value={searchInput}
        onChange={setSearchInput}
        placeholder={placeholder || t("Search")}
        inputProps={{
          addonLeft: <SearchOutlined />,
          addonRight: (
            <div className="h-8 opacity-50 hover:opacity-100 transition-opacity">
              {searchInput.length > 0 && (
                <Button intent="tertiaryFlat" size="tiny" onPress={() => setSearchInput("")}>
                  <CloseOutlined fontSize="small" />
                </Button>
              )}
            </div>
          ),
        }}
      />
    </div>
  );
});

import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router";
import tw from "tailwind-styled-components";
import { useProductionProcesses } from "../../api/endpoints/production-processes";
import { Plant, Product, ProductionProcess } from "../../api/types";
import { Badge } from "../../components/Badge";
import { Loading } from "../../components/Loading";
import { Heading4 } from "../../components/Typography";
import { RenderedFlow } from "../../page-components/product-detail/RenderedFlow";
import { useElementaries } from "../../state/elementaries";
import { HARDCODED_ELEMENTARIES_WITHOUT_RECIPE } from "../../state/HARD_CODED";
import { useActivePlant } from "../../state/plants";
import { useProduct, useSelectedProductId, useUpdateProduct } from "../../state/products";
import { useSplash } from "../../state/splash";
import { useGetLinkWithParams } from "../../url/useGetLinkWithParams";
import { EditFlowNav } from "./EditFlowNav";

const useSubmitProductionProcess = ({
  activePlant,
  selectedProduct,
  selectedProcessId,
}: {
  activePlant?: Plant;
  selectedProduct: Product;
  selectedProcessId?: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate: updateProduct, isPending: loading } = useUpdateProduct();
  const [searchParams] = useSearchParams();
  const { setSplash } = useSplash();

  const onSubmit = useCallback(() => {
    if (!activePlant || !selectedProcessId) return;

    updateProduct(
      {
        productId: selectedProduct.id,
        product: {
          ...selectedProduct,
          production_process_id: selectedProcessId,
        },
      },
      {
        onSuccess: () => {
          if (HARDCODED_ELEMENTARIES_WITHOUT_RECIPE.includes(selectedProduct.elementary_id)) {
            navigate({ pathname: `/products/${selectedProduct.id}` });
            if (selectedProduct.status === "incomplete") {
              setSplash({ message: t("New product created") });
            }
          } else {
            navigate({ pathname: "/edit/product-recipe", search: searchParams.toString() });
          }
        },
      },
    );
  }, [
    activePlant,
    navigate,
    searchParams,
    selectedProcessId,
    selectedProduct,
    setSplash,
    t,
    updateProduct,
  ]);

  return {
    loading,
    onSubmit,
  };
};

const isProcessDisabledForProduct = (process: ProductionProcess, product: Product) => {
  return !process.input_output_per_year?.some((io) => io.year === product.year_under_review);
};

const ProductProductionProcessForm = ({
  selectedProduct,
  activePlant,
}: {
  selectedProduct: Product;
  activePlant: Plant;
}) => {
  const navigate = useNavigate();
  const getLinkWithParams = useGetLinkWithParams();
  const onPrev = () => navigate(getLinkWithParams("/edit/product-specs"));

  const [selectedProcessId, setSelectedProcessId] = useState<string | undefined>(
    selectedProduct.production_process_id,
  );

  const { elementariesMap } = useElementaries();
  const { data: productionProcesses } = useProductionProcesses({ plantId: activePlant.id });

  const { loading, onSubmit } = useSubmitProductionProcess({
    activePlant,
    selectedProduct,
    selectedProcessId,
  });

  const productionProcessOptions = useMemo(
    () =>
      productionProcesses.filter((pp) => pp.elementary_ids.includes(selectedProduct.elementary_id)),
    [productionProcesses, selectedProduct.elementary_id],
  );

  // If only a single process is available, select it automatically
  useEffect(() => {
    if (productionProcessOptions.length === 1) {
      const process = productionProcessOptions[0];
      if (!isProcessDisabledForProduct(process, selectedProduct)) {
        setSelectedProcessId(process.id);
      }
    }
  }, [productionProcessOptions, selectedProduct]);

  return (
    <div className="flex-grow flex flex-col gap-8 overflow-hidden mx-auto w-full max-w-6xl pb-20">
      <form
        className="flex-grow flex flex-col gap-5 overflow-hidden"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="flex-grow flex flex-col gap-5 overflow-scroll p-2">
          {productionProcessOptions.map((process) => (
            <ProductionProcessItem
              key={process.id}
              item={process}
              materialName={elementariesMap[selectedProduct.elementary_id].name_en}
              isActive={process.id === selectedProcessId}
              isDisabled={isProcessDisabledForProduct(process, selectedProduct)}
              onSelectItem={setSelectedProcessId}
            />
          ))}
        </div>
        <EditFlowNav
          onPrev={onPrev}
          nextSubmit
          nextLoading={loading}
          nextDisabled={!selectedProcessId}
        />
      </form>
    </div>
  );
};

const ProductProductionProcessMain = () => {
  const id = useSelectedProductId();

  const { data: selectedProduct } = useProduct(id!);
  const activePlant = useActivePlant();

  return (
    <ProductProductionProcessForm selectedProduct={selectedProduct} activePlant={activePlant} />
  );
};

export const ProductProductionProcess = () => {
  return (
    <Suspense fallback={<Loading lazy />}>
      <ProductProductionProcessMain />
    </Suspense>
  );
};

const ItemContainer = tw("div")<{ $active?: boolean; $disabled?: boolean }>`
  bg-white
  rounded-lg
  p-8
  border-2
  space-y-3
  hover:shadow
  hover:-translate-y-[1px]
  active:translate-y-0
  ${({ $active }) => $active && "border-energyyellow"}
  ${({ $disabled }) =>
    $disabled
      ? "opacity-70 cursor-not-allowed hover:shadow-none"
      : "cursor-pointer hover:border-energyyellow"}
`;

const ProductionProcessItem = ({
  item,
  materialName,
  isActive,
  isDisabled,
  onSelectItem,
}: {
  item: ProductionProcess;
  materialName: string;
  isActive: boolean;
  isDisabled: boolean;
  onSelectItem: (id: string) => void;
}) => {
  return (
    <ItemContainer
      $active={isActive}
      $disabled={isDisabled}
      onClick={isDisabled ? undefined : () => onSelectItem(item.id)}
    >
      <div className="flex justify-between items-center">
        <Heading4>{item.name}</Heading4>
        <Badge>{materialName}</Badge>
      </div>
      <RenderedFlow flow={item} />
    </ItemContainer>
  );
};

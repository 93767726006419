import { useSuspenseQuery } from "@tanstack/react-query";
import { useGetMe } from "../api/endpoints/manufacturers";

export const useActiveManufacturer = () => {
  const getMe = useGetMe();

  const { data: manufacturer, refetch } = useSuspenseQuery({
    queryKey: ["getMe"],
    queryFn: getMe,
    retry: false,
  });

  return {
    activeManufacturer: manufacturer,
    refetch,
  };
};

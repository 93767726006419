import tw from "tailwind-styled-components";

// We're purposefully deviating from tailwind's scaling system here
// (see design), using 32px for H2.
export const Heading2 = tw("h2")`
  m-0
  text-[2rem]
  leading-10
  text-builtgreen
  font-medium
`;

export const Heading3 = tw("h3")`
  m-0
  text-2xl
  font-bold
  text-builtgreen
`;

export const Heading4 = tw("h4")`
  m-0
  text-xl
  font-bold
  text-builtgreen
`;

export const Text14 = tw("p")`
  m-0
  text-sm
  text-builtgreen
`;

export const Text16 = tw("p")`
  m-0
  text-base
  text-builtgreen
`;

export const Label16 = tw("p")`
  m-0
  text-base
  text-builtgreen
  font-bold
`;

// Apparently Label14 is supposed to be uppercase, but I think it looks terrifble in a few places.
// Let's make it uppercase after the questions around the design system are resolved.
export const Label14 = tw("p")`
  m-0
  text-sm
  text-builtgreen
  font-bold
`;

export const Label12 = tw("p")`
  m-0
  text-xs
  uppercase
  text-neutral-500
  font-bold
`;

export const Label = tw("p")`
  m-0
  text-sm
  uppercase
  text-neutral-500
  font-bold
`;

import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { EndOfScale, Product } from "../../api/types";
import { InfoTooltip } from "../../components/InfoTooltip";
import { Loading } from "../../components/Loading";
import { Heading2, Text16 } from "../../components/Typography";
import { BreakdownPerMaterial } from "./BreakdownPerMaterial";
import { SourcesOfEnvironmentalImpact } from "./SourcesOfEnvironmentalImpact";

const PageContent = ({
  product,
  isUpdating,
  endsOfScale,
}: {
  product: Product;
  isUpdating?: boolean;
  endsOfScale: EndOfScale[];
}) => {
  return (
    <div className="flex-grow">
      {product.lca_results && (
        <div className="flex flex-col gap-24 pt-3">
          <SourcesOfEnvironmentalImpact lcia={product.lca_results} endsOfScale={endsOfScale} />
          <BreakdownPerMaterial lcia={product.lca_results} />
        </div>
      )}
      {!product.lca_results && isUpdating && <Loading />}
    </div>
  );
};

export const ProductDetailPageAnalyse = ({
  product,
  isUpdating,
  endsOfScale,
}: {
  product: Product;
  isUpdating?: boolean;
  endsOfScale: EndOfScale[];
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col pb-8 gap-8 flex-grow">
      <div className="flex flex-col gap-8 flex-grow">
        <div className="-mx-6 p-6 bg-concrete bg-texture flex flex-col gap-1">
          <div className="grid grid-cols-[1fr_auto] items-start gap-8">
            <div>
              <Heading2>{t("Analyse & Optimise")}</Heading2>
              <Text16>{t("Understand the impact areas with the highest potential")}</Text16>
            </div>
            <div className="flex items-center gap-2">
              <Text16 className="font-bold">
                {t("What areas are most important for reduction?")}
              </Text16>
              <InfoTooltip>
                {t(
                  "Use this dashboard to analyze where your products' environmental impact originates to develop effective reduction strategies.",
                )}
              </InfoTooltip>
            </div>
          </div>
        </div>
        <Suspense>
          <PageContent product={product} isUpdating={isUpdating} endsOfScale={endsOfScale} />
        </Suspense>
      </div>
    </div>
  );
};

import tw from "tailwind-styled-components";

export const UserBubble = tw.div<{ $clickable?: boolean }>`
  grid
  place-items-center
  rounded-full
  steelblue-300
  w-[35px]
  h-[35px]
  text-sm
  font-bold
  text-white
  bg-steelblue
  transition-colors
  ${({ $clickable }) => $clickable && "cursor-pointer hover:steelblue-400"}
`;

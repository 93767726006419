import tw from "tailwind-styled-components";
import { ProductionProcessTemplate } from "../../api/types";

const Container = tw("div")<{ $transparent?: boolean }>`
  w-full
  overflow-hidden
  p-5
  rounded-lg
  ${(props) => (props.$transparent ? "bg-transparent" : "bg-gray-100")}
`;

export const RenderedFlow = ({
  flow,
  transparent,
}: {
  flow: ProductionProcessTemplate;
  transparent?: boolean;
}) => {
  const rootStep = flow.steps.find((step) => step.parent_id === null);

  return (
    <Container $transparent={transparent}>
      <div className="overflow-x-auto w-full  flex items-center justify-center">
        {rootStep && <RenderedFlowStep flow={flow} step={rootStep} />}
        <div className="text-4xl">📦</div>
      </div>
    </Container>
  );
};

const RenderedFlowStep = ({
  flow,
  step,
}: {
  flow: ProductionProcessTemplate;
  step: ProductionProcessTemplate["steps"][number];
}) => {
  const children = flow.steps.filter((s) => s.parent_id === step.id);
  return (
    <div className="flex flex-row-reverse">
      <div className="flex items-center">
        <div className="text-xs rounded border bg-white max-w-[110px] py-1 px-2 text-center">
          {step.name}
        </div>
        <div className="w-3 h-3 bg-neutral-200"></div>
      </div>
      {children.length > 0 && (
        <div className="flex flex-col items-end gap-2 relative">
          {children.map((child) => (
            <div key={child.id} className="flex flex-col items-center gap-5">
              <RenderedFlowStep flow={flow} step={child} />
            </div>
          ))}
          {children.length > 1 && (
            <div className="absolute bg-neutral-200 w-2 right-0 h-3/4 top-1/2 -translate-y-1/2 rounded-xl" />
          )}
        </div>
      )}
    </div>
  );
};

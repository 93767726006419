import { ComponentProps, useMemo } from "react";
import { Controller, ControllerProps, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ComboBoxMultiField } from "../components/ComboBoxMultiField";

const ComboBoxMultiFieldMemoized = <TFieldValues extends object>({
  options,
  field: { value, onChange, disabled, ...field },
  fieldState: { error },
  formState,
  ...props
}: Parameters<ComponentProps<typeof Controller<TFieldValues>>["render"]>[0] &
  ComponentProps<typeof ComboBoxMultiField>) => {
  const { t } = useTranslation();

  const usesNumberIds = options.length > 0 && typeof options[0].id === "number";
  const memoizedValue = useMemo(() => {
    return options.filter((opt) => {
      if (!value) return false;

      return (value as (string | number)[]).includes(usesNumberIds ? Number(opt.id) : opt.id);
    });
  }, [options, usesNumberIds, value]);

  return (
    <ComboBoxMultiField
      {...field}
      value={memoizedValue}
      onChange={(_, opt) => {
        const ids = opt.map((o) => (usesNumberIds ? Number(o.id) : o.id));
        onChange(ids);
      }}
      options={options}
      errorMessage={error?.message}
      placeholder={t("Select …")}
      {...props}
    />
  );
};

export const ComboBoxMultiFieldConnected = <TFieldValues extends object>({
  name,
  rules,
  options,
  isRequired,
  ...props
}: Pick<ControllerProps<TFieldValues>, "name" | "rules"> &
  ComponentProps<typeof ComboBoxMultiField>) => {
  const { t } = useTranslation();
  const { control } = useFormContext<TFieldValues>();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired ? t("Required") : undefined,
        ...rules,
      }}
      render={(renderProps) => (
        <ComboBoxMultiFieldMemoized
          {...props}
          {...renderProps}
          options={options}
          isRequired={isRequired}
        />
      )}
    />
  );
};

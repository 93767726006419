import type { ReactNode } from "react";
import { createContext, useCallback, useContext, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

const initialState = {
  adminMode: false,
  setAdminMode: () => {},
};

const Context = createContext<ReturnType<typeof useContextValue>>(initialState);

const useContextValue = () => {
  const [adminMode, setAdminMode] = useState<boolean>(false);

  const toggleAdminMode = useCallback(() => setAdminMode((m) => !m), []);
  useHotkeys("alt+shift+a", toggleAdminMode, [toggleAdminMode]);

  return { adminMode, setAdminMode };
};

export const AdminModeProvider = ({ children }: { children: ReactNode }) => (
  <Context.Provider value={useContextValue()}>{children}</Context.Provider>
);

export const useAdminMode = () => {
  return useContext(Context).adminMode;
};

import { ArrowBackOutlined, DeleteOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useDeletePlant } from "../api/endpoints/plants";
import { Button } from "../components/Button";
import { ConfirmationModal } from "../components/ConfirmationModal";
import { PageContainer } from "../components/PageContainer";
import { TopBar } from "../components/TopBar";
import { Text16 } from "../components/Typography";
import { ManagePlantFields } from "../page-components/company-settings/ManagePlantFields";
import { usePlantForm } from "../page-components/plant-settings/usePlantForm";
import { useActivePlantControl, usePlants } from "../state/plants";
import { showSuccessToast } from "../util/toasts";

const usePlantDeletion = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [deletionStaged, setDeletionStaged] = useState(false);
  const { mutate: deletePlant } = useDeletePlant();

  const { data: plants } = usePlants();
  const { activePlant, setActivePlantId } = useActivePlantControl();

  const nextPlantWithoutDeleted = useMemo(
    () => plants.find((p) => p.id !== activePlant?.id),
    [plants, activePlant?.id],
  );

  const removePlant = () => {
    if (!activePlant) return;

    setDeletionStaged(false);

    deletePlant(
      { plantId: activePlant.id },
      {
        onSuccess: () => {
          if (nextPlantWithoutDeleted) {
            setActivePlantId(nextPlantWithoutDeleted?.id);
          }
          navigate("/company-settings");
          showSuccessToast(
            t("Successfully deleted plant '{{ name }}'", { name: activePlant.name }),
          );
        },
      },
    );
  };

  return {
    deletionStaged,
    removePlant,
    setDeletionStaged,
  };
};

export const EditPlant = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { methods, activePlant, loading, onSubmit } = usePlantForm({ isEdit: true });

  const { deletionStaged, removePlant, setDeletionStaged } = usePlantDeletion();

  return (
    <FormProvider {...methods}>
      <PageContainer $as="form" noValidate onSubmit={methods.handleSubmit(onSubmit)}>
        <TopBar
          icon={
            <Button onPress={() => navigate("/company-settings")} intent="secondary" square>
              <ArrowBackOutlined />
            </Button>
          }
          title={t("Edit plant")}
          input={
            <>
              <Button type="button" intent="tertiaryFlat" onPress={() => setDeletionStaged(true)}>
                <DeleteOutlined />
                {t("Delete plant")}
              </Button>
              <Button type="submit" isDisabled={loading}>
                {loading && <CircularProgress size="24px" />}
                {t("Update plant")}
              </Button>
            </>
          }
        />
        <div className="flex-grow flex flex-col gap-8 py-8 px-8">
          <ManagePlantFields activePlant={activePlant} />
        </div>
      </PageContainer>
      {deletionStaged && (
        <ConfirmationModal
          title={t("Delete Plant")}
          content={
            <>
              <Text16 className="font-bold">{activePlant?.name}</Text16>
              <Text16>{t("Are you sure you want to delete this plant?")}</Text16>
            </>
          }
          onConfirm={removePlant}
          onCancel={() => setDeletionStaged(false)}
        />
      )}
    </FormProvider>
  );
};

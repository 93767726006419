import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePatchProductionProcess } from "../../api/endpoints/production-processes";
import { ProductionProcess } from "../../api/types";
import { Button } from "../../components/Button";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../components/Modal";
import { TextFieldConnected } from "../../form-components/TextFieldConnected";

interface Fields {
  name: string;
}

export const EditProcessNameModal = ({ process }: { process: ProductionProcess }) => {
  const { t } = useTranslation();
  const { mutate: patchProductionProcess } = usePatchProductionProcess();

  const methods = useForm<Fields>({
    defaultValues: {
      name: process.name,
    },
  });

  const onSubmit =
    (close: () => void): SubmitHandler<Fields> =>
    (values) => {
      patchProductionProcess(
        {
          ...process,
          name: values.name,
        },
        {
          onSuccess: close,
        },
      );
    };

  return (
    <Modal>
      {({ close }) => (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit(close))} noValidate>
            <ModalHeader title={t("Edit process name")} onClose={close} />
            <ModalBody>
              <TextFieldConnected name="name" label={t("Process name")} isRequired />
            </ModalBody>
            <ModalFooter>
              <Button intent="secondary" type="button" onPress={close}>
                {t("Cancel")}
              </Button>
              <Button intent="primary" type="submit">
                {t("Save")}
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      )}
    </Modal>
  );
};

import { useCallback } from "react";
import { ProductionProcessTemplate } from "../types";
import { useApi } from "../useApi";

export const useGetProductionProcessTemplates = () => {
  const api = useApi<ProductionProcessTemplate[]>({ method: "GET" });

  return useCallback(
    () =>
      api({
        url: `/v1/production-processes/templates`,
      }),
    [api],
  );
};

import { useSuspenseQuery } from "@tanstack/react-query";
import { useGetDeclaration } from "../api/endpoints/declarations";

export const useDeclarations = ({
  productId,
  plantId,
  manufacturerId,
}: {
  productId: string;
  plantId: string;
  manufacturerId: string;
}) => {
  const getDeclarations = useGetDeclaration();

  return useSuspenseQuery({
    queryFn: () => getDeclarations({ productId, plantId, manufacturerId }),
    queryKey: [manufacturerId, plantId, productId, "declarations"],
  });
};

import { cva } from "class-variance-authority";
import { forwardRef } from "react";
import { Label as LabelPrimitive, LabelProps } from "react-aria-components";

const styles = cva("text-sm font-bold", {
  variants: {
    isDisabled: {
      true: "text-neutral-400",
    },
  },
});

export const FieldLabel = forwardRef<
  HTMLLabelElement,
  Omit<LabelProps, "className" | "styles"> & {
    isRequired?: boolean;
    isDisabled?: boolean;
  }
>(({ children, isRequired, isDisabled, ...props }, ref) => {
  return (
    <LabelPrimitive className={styles({ isDisabled })} {...props} ref={ref}>
      <span className={isRequired ? "after:ml-0.5 after:text-red-500 after:content-['*']" : ""}>
        {children}
      </span>
    </LabelPrimitive>
  );
});

import { useState } from "react";

export const useProductSorting = () => {
  const [sort, setSort] = useState<{
    by: string;
    direction: "asc" | "desc";
    label: string;
  }>({
    by: "created_at",
    direction: "desc",
    label: "Recently added",
  });

  return {
    sort,
    setSort,
  };
};

import { SearchOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useMemo, useRef, useState } from "react";
import { DialogTrigger } from "react-aria-components";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useDebounce from "react-use/lib/useDebounce";
import { SupplierMaterial } from "../../../../api/types";
import { Alert } from "../../../../components/Alert";
import { Button } from "../../../../components/Button";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../../../components/Modal";
import { TextField } from "../../../../components/TextField";
import { Heading3, Text14 } from "../../../../components/Typography";
import { useElementaries } from "../../../../state/elementaries";
import { useEpd, useSearchEPDs } from "../../../../state/epds";
import { FormFields, SecondaryDatasetWithOptions } from "../types";
import { PrimaryData, PrimaryDataItem } from "./PrimaryData";
import { SecondaryData } from "./SecondaryData";

const useEnvironmentalDataSearch = ({
  epdId,
  productName,
  supplierName,
  existingMaterial,
}: {
  epdId: string | null;
  productName?: string;
  supplierName?: string;
  existingMaterial?: SupplierMaterial | null;
}) => {
  const { data: epd } = useEpd(epdId || undefined);
  const { data: primaryData = [], isPending, mutate: search } = useSearchEPDs();

  const methods = useFormContext<FormFields>();

  useDebounce(
    () => {
      if (!productName || !supplierName) return;

      search({
        searchTerm: [productName, supplierName].join(" ").trim(),
        language: "en",
        maxResults: 10,
      });

      if (
        !existingMaterial ||
        (existingMaterial.name !== productName &&
          existingMaterial.supplier_plant?.supplier_company.name !== supplierName)
      ) {
        methods.setValue("epdId", null);
      }
    },
    existingMaterial ? 0 : 500,
    [productName, supplierName, existingMaterial],
  );

  // Deduplicated primary data
  const resultingPrimaryData = useMemo(() => {
    const uniqueData = new Map();
    [...(epd ? [epd] : []), ...primaryData].forEach((item) => {
      uniqueData.set(item.id, item);
    });
    return Array.from(uniqueData.values());
  }, [epd, primaryData]);

  return { primaryData: resultingPrimaryData, isPending };
};

export const LinkEnvironmentalData = ({
  existingMaterial,
}: {
  existingMaterial?: SupplierMaterial | null;
}) => {
  const { t } = useTranslation();
  const methods = useFormContext<FormFields>();

  const { elementariesMap } = useElementaries();
  const elementaryId = methods.watch("elementaryId");
  const epdId = methods.watch("epdId");

  const materialMatching = elementariesMap[elementaryId]?.matching;

  const productName = methods.watch("productName");
  const supplierCompanyName = methods.watch("supplierCompanyName");

  const supplierName = existingMaterial
    ? existingMaterial.supplier_plant?.supplier_company.name
    : supplierCompanyName;

  const { primaryData, isPending } = useEnvironmentalDataSearch({
    epdId,
    productName,
    supplierName,
    existingMaterial,
  });

  const linkedSecondaryData: SecondaryDatasetWithOptions[] = useMemo(
    () =>
      existingMaterial?.secondary_data ||
      materialMatching?.map((match) => ({ activity_name: match[0] })) ||
      [],
    [existingMaterial, materialMatching],
  );

  return (
    <>
      <Heading3>
        {t("Link Environmental Data")}
        {isPending && <CircularProgress size="24px" />}
      </Heading3>
      {primaryData.length > 0 && <PrimaryData primaryData={primaryData} />}
      <div className="space-y-2">
        <hr />
        <div className="flex items-center justify-between">
          <Text14>{t("The right EPD isn't shown here?")}</Text14>
          <DialogTrigger>
            <Button intent="tertiary" size="small">
              <SearchOutlined />
              {t("Search")}
            </Button>
            <Modal size="lg">{({ close }) => <SearchEPDModalContent onClose={close} />}</Modal>
          </DialogTrigger>
        </div>
      </div>
      {linkedSecondaryData.length > 0 && (
        <SecondaryData data={linkedSecondaryData} hasOtherOptions={primaryData.length > 0} />
      )}
      {!epdId && linkedSecondaryData.length > 0 && (
        <Alert intent="info">
          <Text14>
            {primaryData.length === 0 && t("No EPD data found.")}{" "}
            {t("Using conservative data from secondary databases.")}
            <br />
            {t(
              "Request EPD from your supplier to improve accuracy of environmental impact calculation.",
            )}
          </Text14>
        </Alert>
      )}
    </>
  );
};

const SearchEPDModalContent = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const { data: primaryData = [], mutate: search } = useSearchEPDs();
  const methods = useFormContext<FormFields>();
  const initialEpdId = useRef(methods.getValues("epdId"));

  const sortedPrimaryData = useMemo(() => {
    return [...primaryData].sort((a, b) => {
      if (a.can_be_used !== b.can_be_used) {
        return b.can_be_used ? 1 : -1;
      }
      return a.name_en.localeCompare(b.name_en);
    });
  }, [primaryData]);

  useDebounce(
    () => {
      search({ searchTerm, language: "en", maxResults: 10 });
    },
    500,
    [searchTerm],
  );

  return (
    <>
      <ModalHeader title={t("Search EPD")} onClose={onClose} />
      <ModalBody>
        <div className="space-y-6">
          <TextField
            autoFocus
            aria-label={t("Search EPD")}
            placeholder={t("Search for a product or supplier to find the right EPD")}
            value={searchTerm}
            onChange={setSearchTerm}
            inputProps={{
              addonLeft: <SearchOutlined />,
            }}
          />
          {sortedPrimaryData.some((item) => !item.can_be_used) && (
            <Alert intent="info">
              {t(
                "Some of the EPDs listed below aren't selectable, because they're not A2+ compliant, or we don't have enough data on it yet.",
              )}
            </Alert>
          )}
          {sortedPrimaryData.map((item) => (
            <PrimaryDataItem key={item.id} item={item} />
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          intent="secondary"
          onPress={() => {
            if (initialEpdId.current) {
              methods.setValue("epdId", initialEpdId.current);
            }
            onClose();
          }}
        >
          {t("Cancel")}
        </Button>
        <Button onPress={onClose}>{t("Select")}</Button>
      </ModalFooter>
    </>
  );
};

import { Product } from "../api/types";

export const areProductsComparable = (product1: Product, product2: Product) => {
  return (
    product1.id !== product2.id &&
    product1.metadata.id === product2.metadata.id &&
    product1.unit === product2.unit &&
    product1.status !== "incomplete" &&
    product2.status !== "incomplete"
  );
};

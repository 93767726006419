import { useCallback, useState } from "react";
import { Product } from "../../api/types";

export const useProductSelection = ({ products }: { products: Product[] }) => {
  const [isSelectMode, setSelectMode] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<Set<Product>>(new Set());

  const toggleSelect = useCallback((product: Product) => {
    setSelectedProducts((prev) => {
      const next = new Set(prev);
      if (prev.has(product)) {
        next.delete(product);
      } else {
        next.add(product);
      }
      return next;
    });
  }, []);

  const toggleSelectMode = useCallback(() => setSelectMode((prev) => !prev), []);

  const toggleSelectAll = useCallback(() => {
    if (selectedProducts.size < products.length) {
      setSelectedProducts(new Set(products));
    } else {
      setSelectedProducts(new Set());
    }
  }, [products, selectedProducts.size]);

  const resetSelectedProducts = useCallback(() => setSelectedProducts(new Set()), []);

  return {
    isSelectMode,
    toggleSelectMode,
    toggleSelect,
    selectedProducts,
    setSelectedProducts,
    toggleSelectAll,
    resetSelectedProducts,
  };
};

import { Edit } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { t } from "i18next";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useManagementApi } from "../auth/auth";
import { Button } from "../components/Button";
import { TextFieldConnected } from "../form-components/TextFieldConnected";

interface Fields {
  name: string;
  company: string;
}

export const EditUserDialog = () => {
  const [open, setOpen] = useState(false);
  const { patchUserMetadata } = useManagementApi();

  const methods = useForm<Fields>({});

  const onSubmit: SubmitHandler<Fields> = async (values) => {
    await patchUserMetadata({
      name: values.name,
      company: values.company,
    });

    setOpen(false);
  };

  return (
    <div>
      <Button onPress={() => setOpen(true)}>
        <Edit fontSize="small" />
        {t("Edit")}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t("Edit")}</DialogTitle>
        <FormProvider {...methods}>
          <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
            <DialogContent className="space-y-4 min-w-80">
              <TextFieldConnected autoFocus name="name" label="Name" />
              <TextFieldConnected name="company" label="Company" />
            </DialogContent>
            <DialogActions>
              <Button type="submit">{t("Submit")}</Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </div>
  );
};

import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import {
  useDeleteRemoveUser,
  useGetMyProfile,
  useGetProfiles,
  usePostInviteUser,
  usePostTransferOwnership,
} from "../api/endpoints/profiles";
import { useActiveManufacturer } from "./manufacturers";

export const useProfiles = () => {
  const { activeManufacturer } = useActiveManufacturer();

  const getProfiles = useGetProfiles();

  return useSuspenseQuery({
    queryFn: () => getProfiles({ manufacturerId: activeManufacturer.id }),
    queryKey: [activeManufacturer.id, "profiles"],
  });
};

export const useMyProfile = () => {
  const getMyProfile = useGetMyProfile();

  return useSuspenseQuery({
    queryFn: getMyProfile,
    queryKey: ["my-profile"],
  });
};

export const useInviteUser = () => {
  const queryClient = useQueryClient();
  const { activeManufacturer } = useActiveManufacturer();
  const postInviteUser = usePostInviteUser();

  return useMutation({
    mutationFn: (email: string) => postInviteUser({ manufacturerId: activeManufacturer.id, email }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [activeManufacturer.id, "profiles"] });
    },
  });
};

export const useRemoveUser = () => {
  const queryClient = useQueryClient();
  const { activeManufacturer } = useActiveManufacturer();
  const removeUser = useDeleteRemoveUser();

  return useMutation({
    mutationFn: (profileId: string) =>
      removeUser({ manufacturerId: activeManufacturer.id, profileId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [activeManufacturer.id, "profiles"] });
    },
  });
};

export const useTransferOwnership = () => {
  const queryClient = useQueryClient();
  const { activeManufacturer } = useActiveManufacturer();
  const transferOwnership = usePostTransferOwnership();

  return useMutation({
    mutationFn: (profileId: string) =>
      transferOwnership({ manufacturerId: activeManufacturer.id, profileId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [activeManufacturer.id, "profiles"] });
      queryClient.invalidateQueries({ queryKey: ["my-profile"] });
    },
  });
};

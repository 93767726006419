import { AddOutlined, FactoryOutlined, UploadOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { FileTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { useBulkUploadPlants } from "../api/endpoints/plants";
import type { Plant } from "../api/types";
import { useMaxPlantsCount } from "../auth/auth";
import { Button } from "../components/Button";
import { ClickableCard } from "../components/ClickableCard";
import { Link } from "../components/Link";
import { Heading3, Label, Text16 } from "../components/Typography";
import { useActiveManufacturer } from "../state/manufacturers";
import { useActivePlantControl, usePlants } from "../state/plants";
import { showSuccessToast } from "../util/toasts";

const Content = tw("div")`
  grid
  gap-5
  grid-cols-1 md:grid-cols-2 lg:grid-cols-3
`;

export const ManagePlants = () => {
  const { activeManufacturer } = useActiveManufacturer();
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const { setActivePlantId } = useActivePlantControl();
  const { data: plants } = usePlants();
  const maxPlants = useMaxPlantsCount();

  const bulkUploadPlants = useBulkUploadPlants();
  const [uploadLoading, setUploadLoading] = useState(false);

  const handleUpload = async (files: FileList | null) => {
    if (!files) return;

    setUploadLoading(true);

    try {
      const { imported, updated, skipped, failed, errors } = await bulkUploadPlants({
        manufacturerId: activeManufacturer.id,
        file: files[0],
      });

      showSuccessToast(
        `Plants uploaded successfully. Imported: ${imported}, Updated: ${updated}, Skipped: ${skipped}, Failed: ${failed} \n\n${errors.join("\n")}`,
      );
      queryClient.invalidateQueries({ queryKey: [activeManufacturer.id, "plants"], exact: true });
    } catch (e) {
      console.error(e);
    }

    setUploadLoading(false);
  };

  return (
    <div className="flex-grow flex flex-col gap-8 py-4">
      <div className="grid grid-cols-2 items-center justify-between gap-3">
        <div>
          <Label>{t("Production Plants")}</Label>
          {maxPlants - plants.length < 2 && (
            <p>
              {t(
                "You have {{ plants }} plants out of {{ maxPlants }} allowed. To increase the limit please contact the support.",
                { plants: plants.length, maxPlants },
              )}
            </p>
          )}
        </div>
        <div className="flex gap-6 justify-end">
          <FileTrigger onSelect={handleUpload}>
            <Button intent="secondary" isDisabled={uploadLoading || plants.length >= maxPlants}>
              {uploadLoading ? <CircularProgress size="24px" /> : <UploadOutlined />}
              {t("Upload plants")}
            </Button>
          </FileTrigger>
          <Link
            intent="secondary"
            isDisabled={plants.length >= maxPlants}
            href="/manage-plants/new"
          >
            <AddOutlined />
            {t("New plant")}
          </Link>
        </div>
      </div>
      {plants.length > 0 && (
        <Content>
          {plants.map((item) => (
            <PlantCard key={item.id} item={item} setActivePlantId={setActivePlantId} />
          ))}
        </Content>
      )}
    </div>
  );
};

const PlantCard = ({
  item,
  setActivePlantId,
}: {
  item: Plant;
  setActivePlantId: (id: string) => void;
}) => {
  const navigate = useNavigate();

  return (
    <ClickableCard
      className="group relative"
      onClick={() => {
        setActivePlantId(item.id);
        navigate("/manage-plants/edit");
      }}
    >
      <div className="space-y-2">
        <FactoryOutlined className="text-neutral-500" />
        <div className="spcae-y-1">
          <Heading3>{item.name}</Heading3>
          <Text16 className="text-neutral-500">{item.city}</Text16>
        </div>
      </div>
    </ClickableCard>
  );
};

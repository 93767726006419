import { useMutation, useQuery } from "@tanstack/react-query";
import { useGetEpd, usePostSearchResults } from "../api/endpoints/epds";

export const useSearchEPDs = () => {
  const postSearchResults = usePostSearchResults();

  return useMutation({
    mutationFn: (params: { searchTerm: string; language?: string; maxResults?: number }) =>
      postSearchResults(params),
  });
};

export const useEpd = (id?: string) => {
  const getEpd = useGetEpd();

  return useQuery({
    queryKey: ["epds", id],
    queryFn: () => {
      if (!id) return;
      return getEpd(id);
    },
    enabled: !!id,
    placeholderData: (data) => data,
  });
};

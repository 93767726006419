import { SearchOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../components/Button";
import { PageContainer } from "../components/PageContainer";
import { TopBar } from "../components/TopBar";
import { SearchFields } from "../page-components/SearchFields";
import { SearchResults } from "../page-components/SearchResults";
import { useSearchEPDs } from "../state/epds";

interface SearchFormValues {
  productName: string;
  manufacturer: string;
  language?: string;
}

export const Search = () => {
  const [searchedLanguage, setSearchedLanguage] = useState<string | undefined>(undefined);
  const { mutate: search, isPending, data: searchResults } = useSearchEPDs();
  const { t } = useTranslation();

  const handleSubmit = (values: SearchFormValues) => {
    const language = values.language?.toLowerCase();

    setSearchedLanguage(language);

    search({
      searchTerm: [values.productName, values.manufacturer].join(" ").trim(),
      language,
    });
  };

  const methods = useForm<SearchFormValues>({
    defaultValues: {
      productName: "",
      manufacturer: "",
      language: "en",
    },
  });

  return (
    <div className="flex flex-col gap-6 pb-8">
      <FormProvider {...methods}>
        <PageContainer
          $as="form"
          className="space-y-10"
          onSubmit={methods.handleSubmit(handleSubmit)}
          noValidate
        >
          <TopBar
            title={t("Search")}
            icon={<SearchOutlined />}
            input={
              <Button type="submit" isDisabled={isPending}>
                {isPending && <CircularProgress size="24px" />}
                {t("Search")}
              </Button>
            }
          />
          <SearchFields />
          <SearchResults results={searchResults || []} searchedLanguage={searchedLanguage} />
        </PageContainer>
      </FormProvider>
    </div>
  );
};

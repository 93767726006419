import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useActiveManufacturer } from "../../state/manufacturers";
import { useActivePlant } from "../../state/plants";
import { ProductionProcess, ProductionProcessTemplate } from "../types";
import { useApi } from "../useApi";

export const useProductionProcesses = ({ plantId }: { plantId: string }) => {
  const api = useApi<ProductionProcess[]>();
  const { activeManufacturer } = useActiveManufacturer();

  const getProductionProcesses = useCallback(
    () =>
      api({
        url: `/v1/manufacturers/${activeManufacturer?.id}/plants/${plantId}/production-processes`,
      }),
    [api, activeManufacturer, plantId],
  );

  return useSuspenseQuery({
    queryKey: [activeManufacturer.id, plantId, "production-processes"],
    queryFn: getProductionProcesses,
  });
};

export const usePostProductionProcess = () => {
  const queryClient = useQueryClient();
  const api = useApi<ProductionProcess>({ method: "POST" });
  const { activeManufacturer } = useActiveManufacturer();
  const activePlant = useActivePlant();

  const mutationFn = useCallback(
    ({
      productionProcessTemplateId,
    }: {
      productionProcessTemplateId: ProductionProcessTemplate["id"];
    }) =>
      api({
        url: `/v1/manufacturers/${activeManufacturer.id}/plants/${activePlant.id}/production-processes`,
        data: {
          template_id: productionProcessTemplateId,
        },
      }),
    [activeManufacturer.id, activePlant.id, api],
  );

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [activeManufacturer.id, "plants"],
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: [activeManufacturer.id, activePlant.id, "production-processes"],
        exact: true,
      });
    },
  });
};

export const usePatchProductionProcess = () => {
  const queryClient = useQueryClient();
  const api = useApi<ProductionProcess>();
  const { activeManufacturer } = useActiveManufacturer();
  const activePlant = useActivePlant();

  const mutationFn = useCallback(
    (productionProcess: ProductionProcess) =>
      api({
        url: `/v1/manufacturers/${activeManufacturer.id}/plants/${activePlant.id}/production-processes/${productionProcess.id}`,
        method: "PUT",
        data: productionProcess,
      }),
    [activeManufacturer, api, activePlant],
  );

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [activeManufacturer.id, "plants"],
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: [activeManufacturer.id, activePlant.id, "production-processes"],
        exact: true,
      });
    },
  });
};

export const useDeleteProductionProcess = () => {
  const queryClient = useQueryClient();
  const api = useApi<void>({ method: "DELETE" });
  const { activeManufacturer } = useActiveManufacturer();
  const activePlant = useActivePlant();

  const mutationFn = useCallback(
    (productionProcessId: string) =>
      api({
        url: `/v1/manufacturers/${activeManufacturer.id}/plants/${activePlant.id}/production-processes/${productionProcessId}`,
      }),
    [activeManufacturer.id, activePlant.id, api],
  );

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [activeManufacturer.id, "plants"],
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: [activeManufacturer.id, activePlant.id, "production-processes"],
        exact: true,
      });
    },
  });
};

import { Suspense } from "react";
import { useLocation } from "react-router";
import { Elementary } from "../../api/types";
import { useMaterial } from "../../state/materials";
import { usePrechainProduct } from "../../state/prechainProducts";
import { Form } from "./suppliers-and-materials/Form";
import { MaterialsList } from "./suppliers-and-materials/MaterialsList";
import { PrechainProductForm } from "./suppliers-and-materials/PrechainProductForm";

export const ManageSuppliersAndMaterials = () => {
  const location = useLocation();
  const [last, secondLast] = location.pathname.split("/").reverse();
  const isNewRawMaterialPage = last === "new-raw-material";
  const isNewPackagingPage = last === "new-packaging";
  const isNewPrechainProductPage = last === "new-prechain-product";

  const isEditPage = secondLast === "edit";
  const materialId = isEditPage ? last : undefined;

  const isEditPrechainProductPage = secondLast === "edit-prechain-product";
  const prechainProductId = isEditPrechainProductPage ? last : undefined;

  if (isNewPrechainProductPage) return <PrechainProductForm prechainProduct={null} />;
  if (isEditPrechainProductPage && prechainProductId)
    return <EditPrechainProductPage prechainProductId={prechainProductId} />;

  if (isNewRawMaterialPage || isNewPackagingPage || isEditPage)
    return (
      <Suspense>
        <SupplierMaterialForm
          materialId={materialId}
          type={
            isNewRawMaterialPage ? "raw_materials" : isNewPackagingPage ? "packaging" : undefined
          }
        />
      </Suspense>
    );

  return (
    <Suspense>
      <MaterialsList />
    </Suspense>
  );
};

const SupplierMaterialForm = ({
  materialId,
  type,
}: {
  materialId?: string;
  type?: Elementary["type"];
}) => {
  const existingMaterial = useMaterial(materialId);

  if (!!materialId && !existingMaterial) return null;

  return <Form existingMaterial={existingMaterial} type={type} />;
};

const EditPrechainProductPage = ({ prechainProductId }: { prechainProductId: string }) => {
  const prechainProduct = usePrechainProduct(prechainProductId);

  if (!prechainProduct) return null;

  return <PrechainProductForm prechainProduct={prechainProduct} />;
};

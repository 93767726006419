import type { ReactNode } from "react";
import { createContext, useContext, useState } from "react";

const initialState = {
  showBanner: true,
  setShowBanner: () => {},
};

const Context = createContext<ReturnType<typeof useContextValue>>(initialState);

const useContextValue = () => {
  const [showBanner, setShowBanner] = useState<boolean>(true);

  return { showBanner, setShowBanner };
};

export const BannerProvider = ({ children }: { children: ReactNode }) => (
  <Context.Provider value={useContextValue()}>{children}</Context.Provider>
);

export const useBanner = () => {
  return useContext(Context);
};

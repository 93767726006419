import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useActiveManufacturer } from "../../state/manufacturers";
import { BulkUploadResult, Plant } from "../types";
import { useApi } from "../useApi";

export const useGetPlants = () => {
  const api = useApi<Plant[]>({
    method: "GET",
  });

  return useCallback(
    ({ manufacturerId }: { manufacturerId: string }) => {
      return api({ url: `/v1/manufacturers/${manufacturerId}/plants` });
    },
    [api],
  );
};

export const usePostPlant = () => {
  const queryClient = useQueryClient();
  const { activeManufacturer } = useActiveManufacturer();
  const api = useApi<Plant>({
    method: "POST",
  });

  const mutationFn = useCallback(
    ({ plant }: { plant: Omit<Plant, "id" | "input_output_schema" | "waste_treatment_schema"> }) =>
      api({ url: `/v1/manufacturers/${activeManufacturer.id}/plants`, data: plant }),
    [activeManufacturer.id, api],
  );

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [activeManufacturer.id, "plants"],
        exact: true,
      });
    },
  });
};

export const usePatchPlant = () => {
  const { activeManufacturer } = useActiveManufacturer();
  const queryClient = useQueryClient();
  const api = useApi<Plant>({
    method: "PUT",
  });

  const mutationFn = useCallback(
    ({ plantId, plant }: { plantId: string; plant: Plant }) =>
      api({ url: `/v1/manufacturers/${activeManufacturer.id}/plants/${plantId}`, data: plant }),
    [api, activeManufacturer],
  );

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [activeManufacturer.id, "plants"],
        exact: true,
      });
    },
  });
};

export const useDeletePlant = () => {
  const queryClient = useQueryClient();
  const { activeManufacturer } = useActiveManufacturer();
  const api = useApi<Plant>({
    method: "DELETE",
  });

  const mutationFn = useCallback(
    ({ plantId }: { plantId: string }) =>
      api({ url: `/v1/manufacturers/${activeManufacturer.id}/plants/${plantId}` }),
    [activeManufacturer.id, api],
  );

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [activeManufacturer.id, "plants"],
        exact: true,
      });
    },
  });
};

export const useBulkUploadPlants = () => {
  const api = useApi<BulkUploadResult>({
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
  });

  return useCallback(
    ({ manufacturerId, file }: { manufacturerId: string; file: File }) => {
      const formData = new FormData();
      formData.append("file", file);

      return api({
        url: `/v1/manufacturers/${manufacturerId}/plants/bulk`,
        data: formData,
      });
    },
    [api],
  );
};

import tw from "tailwind-styled-components";

// TODO: Not a great abstraction with all these props,
// refactor into a component with proper variants using cva, see Button.tsx for example.

/**
 * A container for an icon with a few variants for different states.
 * used for the icons in the sidebar but also in other cases.
 */
export const IconContainer = tw("div")<{
  $active?: boolean;
  $blue?: boolean;
  $green?: boolean;
  $pale?: boolean;
  $bordered?: boolean;
}>`
  flex-shrink-0
  text-builtgreen
  transition-all
  ${({ $bordered }) => $bordered && "border border-neutral-300"}
  ${({ $active, $pale, $blue, $green }) =>
    $active
      ? $blue
        ? "bg-blue-500 text-white"
        : $green
          ? "bg-builtgreen text-energyyellow"
          : "bg-energyyellow"
      : $pale
        ? "bg-neutral-100"
        : "bg-white"}
  ${({ $active }) =>
    $active ? "shadow-e-md" : "group-hover:shadow-e-sm group-hover:-translate-y-[1px]"}
  h-12 w-12
  flex items-center justify-center
`;

import { VariantProps, cva, cx } from "class-variance-authority";
import { ReactNode } from "react";
import { Button as ButtonPrimitive, ButtonProps } from "react-aria-components";

export const buttonStyles = cva(
  [
    "flex",
    "items-center",
    "justify-center",
    "gap-1",
    "transition-all",
    "outline-none",
    "hover:-translate-y-[1px]",
    "active:translate-y-0",
    "disabled:opacity-50",
    "disabled:cursor-not-allowed",
    "font-bold",
  ],
  {
    variants: {
      isDisabled: {
        true: "cursor-not-allowed opacity-50",
        false: "cursor-pointer",
      },
      intent: {
        primary: ["bg-energyyellow", "text-builtgreen", "border border-transparent"],
        secondary: ["bg-neutral-100", "text-builtgreen", "border-none"],
        tertiary: [
          "bg-transparent",
          "text-builtgreen",
          "border",
          "border-neutral-300 hover:border-neutral-500",
        ],
        // Like tertiary but without a border.
        // we should probably merge these with tertiary
        // and sync with design to adjust their design system.
        tertiaryFlat: ["bg-transparent", "text-builtgreen", "border border-transparent"],
        danger: ["bg-red-500", "text-white"],
        link: "h-auto inline-flex !justify-start text-bold underline",
      },
      size: {
        tiny: "text-xs h-8 rounded-lg px-2",
        small: "text-sm h-10 rounded-lg px-2",
        medium: "text-base h-12 px-4",
        large: "text-lg h-14 px-4",
      },
      square: {
        true: "!px-0",
      },
    },
    compoundVariants: [
      { intent: "primary", size: "medium", class: "shadow-e-sm hover:shadow-e-md" },
      { intent: "secondary", size: "medium", class: "shadow-e-sm hover:shadow-e-md" },
      { size: "tiny", square: true, class: "w-8" },
      { size: "small", square: true, class: "w-10" },
      { size: "medium", square: true, class: "w-12" },
      { size: "large", square: true, class: "w-14" },
      { intent: "link", class: "!px-0" },
    ],
    defaultVariants: {
      intent: "primary",
      size: "medium",
      square: false,
    },
  },
);

/**
 * Our main button component, with a few variants for different intents and sizes.
 */
export const Button = ({
  intent,
  size,
  square,
  ...props
}: Omit<ButtonProps, "className" | "styles" | "children"> &
  VariantProps<typeof buttonStyles> & {
    children?: ReactNode;
  }) => {
  return (
    <ButtonPrimitive
      className={buttonStyles({ intent, size, square, isDisabled: props.isDisabled })}
      {...props}
    />
  );
};

const toggleButtonStyles = cva([], {
  variants: {
    active: {
      true: "text-builtgreen",
      false: "text-neutral-400",
    },
  },
  defaultVariants: {
    active: false,
  },
});

export const ToggleButton = ({
  intent,
  size,
  square,
  active,
  ...props
}: Omit<ButtonProps, "className" | "styles" | "children"> &
  VariantProps<typeof buttonStyles> &
  VariantProps<typeof toggleButtonStyles> & {
    children?: ReactNode;
  }) => {
  return (
    <ButtonPrimitive
      className={cx(buttonStyles({ intent, size, square }), toggleButtonStyles({ active }))}
      {...props}
    />
  );
};

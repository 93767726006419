import { ComponentProps } from "react";
import { Controller, ControllerProps, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TextField } from "../components/TextField";

export const TextFieldConnected = <TFieldValues extends object>({
  name,
  isRequired,
  rules,
  ...props
}: Pick<ControllerProps<TFieldValues>, "name" | "rules"> & ComponentProps<typeof TextField>) => {
  const { t } = useTranslation();
  const { control } = useFormContext<TFieldValues>();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired ? t("Required") : undefined,
        ...rules,
      }}
      render={({ field: { value, disabled, ...field }, fieldState: { error } }) => (
        <TextField
          {...field}
          {...props}
          value={value || ""}
          errorMessage={error?.message}
          isRequired={isRequired}
        />
      )}
    />
  );
};

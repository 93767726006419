import { ComponentProps, forwardRef, ReactNode } from "react";
import { NumberField as NumberFieldPrimitive, NumberFieldProps } from "react-aria-components";
import { FieldLabel } from "./FieldLabel";
import { FieldText } from "./FieldText";
import { Input } from "./Input";
import { InputWithSubfields } from "./InputWithSubfields";

const defaultFormatOptions: Intl.NumberFormatOptions = {
  maximumFractionDigits: 10,
};

export const NumberField = forwardRef<
  HTMLInputElement,
  Omit<NumberFieldProps, "children" | "className"> & {
    label?: string | ReactNode;
    labelProps?: ComponentProps<typeof FieldLabel>;
    errorMessage?: string;
    inputProps?: ComponentProps<typeof Input>;
    placeholder?: string;
    size?: "medium" | "large";
    subfields?: ReactNode;
  }
>(({ inputProps, label, labelProps, errorMessage, size, subfields, ...props }, ref) => {
  return (
    <NumberFieldPrimitive {...props} isWheelDisabled formatOptions={defaultFormatOptions}>
      {label && (
        <FieldLabel isRequired={props.isRequired} isDisabled={props.isDisabled} {...labelProps}>
          {label}
        </FieldLabel>
      )}
      {subfields ? (
        <InputWithSubfields
          ref={ref}
          {...inputProps}
          size={size}
          hasError={!!errorMessage}
          disabled={props.isDisabled}
          subfields={subfields}
        />
      ) : (
        <Input
          ref={ref}
          {...inputProps}
          size={size}
          hasError={!!errorMessage}
          disabled={props.isDisabled}
        />
      )}
      {errorMessage && <FieldText intent="error">{errorMessage}</FieldText>}
    </NumberFieldPrimitive>
  );
});

import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router";
import { Product } from "../../api/types";
import { ProductDetailsFields } from "../../page-components/product-details/ProductDetailsFields";
import { ProductDetailsForm } from "../../page-components/product-details/types";
import { useElementaries } from "../../state/elementaries";
import { useActiveManufacturer } from "../../state/manufacturers";
import { useActivePlant } from "../../state/plants";
import { useProductMetadata } from "../../state/productMetadata";
import {
  useAddProduct,
  useProduct,
  useSelectedProductId,
  useUpdateProduct,
} from "../../state/products";
import { useWarnBeforeUnload } from "../../util/useWarnBeforeUnload";
import { EditFlowNav } from "./EditFlowNav";

const getValuesFromProduct = (product: Product): Partial<ProductDetailsForm> => {
  return {
    elementary_id: product.elementary_id,
    name: product.name,
    description: product.description,
    year_under_review: product.year_under_review,
    use_case: product.use_case,
    unit: product.unit,
  };
};

const ProductDetailsBase = ({ selectedProduct }: { selectedProduct?: Product }) => {
  const { t } = useTranslation();

  const defaultValues = selectedProduct ? getValuesFromProduct(selectedProduct) : undefined;
  const methods = useForm<ProductDetailsForm>({
    defaultValues,
  });

  useWarnBeforeUnload(methods.formState.isDirty);

  const { elementariesMap } = useElementaries();
  const {
    data: { productMetadataMap },
  } = useProductMetadata();

  useEffect(
    function populateForm() {
      if (!selectedProduct) return;

      methods.reset(getValuesFromProduct(selectedProduct));
    },
    [selectedProduct, methods],
  );

  const { mutate: addProduct, isPending: addProductLoading } = useAddProduct();
  const { mutate: updateProduct, isPending: updateProductLoading } = useUpdateProduct();
  const { activeManufacturer } = useActiveManufacturer();
  const activePlant = useActivePlant();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const loading = addProductLoading || updateProductLoading;

  const onSubmit: SubmitHandler<ProductDetailsForm> = ({ categoryName, ...values }) => {
    if (!activePlant) return;

    const goToNextStep = () => {
      // Append search param for the now existing product
      // to ensure the product gets loaded
      // when users go BACK from /edit/product-specs to /edit/product-details
      navigate({ search: searchParams.toString() });
      navigate({ pathname: "/edit/product-specs", search: searchParams.toString() });
    };

    if (selectedProduct) {
      updateProduct(
        {
          productId: selectedProduct.id,
          product: {
            ...selectedProduct,
            ...values,
          },
        },
        { onSuccess: goToNextStep },
      );
    } else {
      addProduct(
        {
          ...values,
          tech_specs: null,
        },
        {
          onSuccess: (response) => {
            searchParams.set("selectedProduct", response.id);
            goToNextStep();
          },
        },
      );
    }
  };

  const elementaryId = methods.watch("elementary_id");

  useEffect(() => {
    if (selectedProduct || !elementaryId) return;

    const elementary = elementariesMap[elementaryId];
    if (!elementary.product_metadata_id) return;
    const metadata = productMetadataMap[elementary.product_metadata_id];

    methods.setValue("description", metadata?.product_description.en ?? "");
    methods.setValue("use_case", metadata?.use_case.en ?? "");
  }, [
    activeManufacturer,
    activePlant,
    elementariesMap,
    methods,
    elementaryId,
    selectedProduct,
    productMetadataMap,
  ]);

  return (
    <div className="flex flex-col flex-grow overflow-hidden">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex-grow flex flex-col justify-between w-full gap-5 pt-5 pb-20 overflow-hidden"
          noValidate
        >
          <ProductDetailsFields existingProduct={!!selectedProduct} />
          <div className="flex flex-col justify-end">
            <EditFlowNav
              nextSubmit
              onPrev={() => navigate("/products")}
              prevLabel={t("Cancel")}
              nextLoading={loading}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

const ProductDetailsExisting = ({ id }: { id: string }) => {
  const { data: selectedProduct } = useProduct(id);

  return <ProductDetailsBase selectedProduct={selectedProduct} />;
};

export const ProductDetails = () => {
  const id = useSelectedProductId();

  return id ? <ProductDetailsExisting id={id} /> : <ProductDetailsBase />;
};

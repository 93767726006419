import { useSuspenseQuery } from "@tanstack/react-query";
import { useGetProductCategories } from "../api/endpoints/product-categories";

export const useProductCategories = () => {
  const getProductCategories = useGetProductCategories();

  return useSuspenseQuery({
    queryKey: ["productCategories"],
    queryFn: getProductCategories,
    select: (productCategories) => {
      return {
        productCategories,
        productCategoriesMap: Object.fromEntries(
          productCategories.map((category) => [category.id, category]),
        ),
      };
    },
  });
};

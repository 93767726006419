import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Link } from "../../components/Link";
import { Label } from "../../components/Typography";

export const EmptyProductList = () => {
  const { t } = useTranslation();
  return (
    <div className="flex-grow w-full flex flex-col items-center justify-center gap-3">
      <div className="flex items-center gap-3">
        <Label>{t("No products added yet")}</Label>
      </div>

      <Link href="/edit/product-details">
        <Add />
        {t("Add new product")}
      </Link>
    </div>
  );
};

import { ajvResolver } from "@hookform/resolvers/ajv";
import { AccountTreeOutlined, LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { Fragment, useCallback, useEffect, useMemo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePatchProductionProcess } from "../../api/endpoints/production-processes";
import {
  DynamicFormJSONSchema,
  ElementType,
  ProductionProcess,
  SupplierMaterial,
} from "../../api/types";
import { Badge } from "../../components/Badge";
import { Button } from "../../components/Button";
import { Card } from "../../components/Card";
import { Link } from "../../components/Link";
import { Label12, Label14, Text14 } from "../../components/Typography";
import { JSONSchemaFieldConnected } from "../../form-components/JSONSchemaFieldConnected";
import { NumberFieldConnected } from "../../form-components/NumberFieldConnected";
import { useElementaries } from "../../state/elementaries";
import { useMaterials } from "../../state/materials";
import { exists } from "../../util/commonUtil";
import { formatUnit } from "../../util/format";
import { useWarnBeforeUnload } from "../../util/useWarnBeforeUnload";

interface ProductionProcessFields {
  year: number;
  production_output: number;
  input_output: Record<string, unknown>;
  packaging: Record<string, number>;
}

const getDefaultInputOutputValues = (
  rootSchema: DynamicFormJSONSchema,
  schema: DynamicFormJSONSchema,
) => {
  return Object.fromEntries(
    Object.entries(schema.properties).map(([key, field]): [string, unknown] => {
      if (field.allOf) {
        const childSchema = rootSchema.$defs[field.allOf[0]?.$ref.replace("#/$defs/", "")];
        return [key, getDefaultInputOutputValues(rootSchema, childSchema)];
      }

      return [key, field.default];
    }),
  );
};

// See https://github.com/adobe/react-spectrum/issues/5524#issuecomment-1841753776
const EMPTY_NUMBER_FIELD_VALUE = NaN;

const useProductionProcessForm = ({
  activeYear,
  process,
  isEditing,
  packagingProducts,
}: {
  activeYear: number;
  process: ProductionProcess;
  isEditing?: boolean;
  packagingProducts: SupplierMaterial[];
}) => {
  const activeYearIO = process.input_output_per_year
    ? process.input_output_per_year.find((x) => x.year === activeYear)
    : null;
  const activeYearPackaging = process.packaging_per_year
    ? process.packaging_per_year.find((x) => x.year === activeYear)
    : null;

  const defaultInputOutputValues = useMemo(
    () =>
      getDefaultInputOutputValues(
        process.input_output_schema,
        process.input_output_schema.$defs.InputOutput,
      ),
    [process.input_output_schema],
  );

  const defaultPackagingValues = useMemo(
    () => Object.fromEntries(packagingProducts.map((p) => [p.id, EMPTY_NUMBER_FIELD_VALUE])),
    [packagingProducts],
  );

  const methods = useForm<ProductionProcessFields>({
    // @ts-expect-error TODO: Try and fix https://ajv.js.org/guide/typescript.html#utility-types-for-schemas
    resolver: ajvResolver(process.input_output_schema, { strict: false }),
    defaultValues: {
      year: activeYear,
      input_output: activeYearIO?.input_output || defaultInputOutputValues,
      packaging: activeYearPackaging?.packaging || defaultPackagingValues,
      production_output: activeYearIO?.production_output || EMPTY_NUMBER_FIELD_VALUE,
    },
  });

  useWarnBeforeUnload(isEditing && methods.formState.isDirty);

  const reset = methods.reset;
  const resetForm = useCallback(() => {
    const activeYearIO = process.input_output_per_year
      ? process.input_output_per_year.find((x) => x.year === activeYear)
      : null;
    const activeYearPackaging = process.packaging_per_year
      ? process.packaging_per_year.find((x) => x.year === activeYear)
      : null;

    reset({
      year: activeYear,
      production_output: activeYearIO?.production_output || EMPTY_NUMBER_FIELD_VALUE,
      input_output: activeYearIO?.input_output || defaultInputOutputValues,
      packaging: activeYearPackaging?.packaging || defaultPackagingValues,
    });
  }, [reset, process, activeYear, defaultInputOutputValues, defaultPackagingValues]);

  useEffect(() => {
    // Reset the form when switching between years, or when the process updates
    resetForm();
  }, [resetForm]);

  const { mutate: patchProductionProcess, isPending } = usePatchProductionProcess();

  const onSubmit: SubmitHandler<ProductionProcessFields> = (values) => {
    patchProductionProcess({
      ...process,
      input_output_per_year: [
        ...(process.input_output_per_year?.filter((x) => x.year !== values.year) ?? []),
        {
          year: values.year,
          production_output: values.production_output,
          input_output: values.input_output,
        },
      ],
      packaging_per_year: [
        ...(process.packaging_per_year?.filter((x) => x.year !== values.year) ?? []),
        {
          year: values.year,
          packaging: Object.fromEntries(
            Object.entries(values.packaging).filter(([, value]) => exists(value) && !isNaN(value)),
          ),
        },
      ],
    });
  };

  return {
    isPending,
    methods,
    onSubmit,
    resetForm,
  };
};

export const ProductionProcessCard = ({
  isEditing,
  isEditingOtherForm,
  setEditingProcessId,
  process,
  activeYear,
}: {
  isEditing?: boolean;
  isEditingOtherForm?: boolean;
  setEditingProcessId: (id: string | null) => void;
  process: ProductionProcess;
  activeYear: number;
}) => {
  const { t } = useTranslation();
  const { elementariesMap } = useElementaries();

  const { packagingMaterials } = useMaterials();
  const scopedPackagingProducts = useMemo(() => {
    return packagingMaterials
      .filter((x) => x.supplier_product)
      .map((x) => x.supplier_product)
      .filter((x) => process.packaging_scoped.includes(x.id));
  }, [packagingMaterials, process.packaging_scoped]);

  const { methods, onSubmit, isPending, resetForm } = useProductionProcessForm({
    activeYear,
    process,
    isEditing,
    packagingProducts: scopedPackagingProducts,
  });

  const ioSchema: DynamicFormJSONSchema | undefined =
    process.input_output_schema?.$defs.InputOutput;

  // Our schema fields grouped
  // - by group (the group is the headline)
  // - potentially by subgroup (the subgroup is another headline)
  // so to render the fields, we have to group them by group and subgroup
  const groupedFieldConfigs = useMemo(() => {
    const fieldConfigs = Object.entries(ioSchema?.properties ?? {});

    const grouped = Object.groupBy(fieldConfigs, ([, fieldConfig]) => fieldConfig.group!);
    return Object.fromEntries(
      Object.entries(grouped).map(([group, items]) => [
        group,
        Object.fromEntries(
          Object.entries(
            // @ts-expect-error `items` will be defined, Object.groupBy seems strangely typed
            Object.groupBy(items, ([, fieldConfig]) => fieldConfig.subgroup ?? ""),
          ).sort(([subgroupA], [subgroupB]) => subgroupA.localeCompare(subgroupB)),
        ),
      ]),
    );
  }, [ioSchema?.properties]);

  const groupDisplay: Record<ElementType, { label: string; isOutput: boolean }> = useMemo(() => {
    return {
      raw_materials: { label: t("Raw materials"), isOutput: false },
      packaging: { label: t("Packaging"), isOutput: false },
      energy_and_fuels: { label: t("Energy and fuels"), isOutput: false },
      ancillary_materials: { label: t("Ancillary materials"), isOutput: false },
      waste: { label: t("Waste"), isOutput: true },
      emissions: { label: t("Emissions"), isOutput: true },
    };
  }, [t]);

  return (
    <FormProvider {...methods}>
      <form
        noValidate
        onSubmit={methods.handleSubmit((values) => {
          onSubmit(values);
          setEditingProcessId(null);
        })}
      >
        <Card
          key={process.id}
          title={
            <div className="flex items-center gap-6">
              <AccountTreeOutlined />
              <Label14>{process.name}</Label14>
              <div className="flex gap-2 flex-wrap">
                {process.elementary_ids.map((elementaryId) => (
                  <Badge key={elementaryId} color="gray" size="sm">
                    {elementariesMap[elementaryId].name_en}
                  </Badge>
                ))}
              </div>
            </div>
          }
          input={
            <Link href={`/production/processes/${process.id}/edit`} size="small" intent="tertiary">
              {t("View process model")}
            </Link>
          }
        >
          <div className="relative">
            <div className="sticky z-10 top-44 w-full flex gap-2 justify-end pointer-events-none [&>button]:pointer-events-auto">
              {isEditing ? (
                <>
                  <Button
                    intent="secondary"
                    size="small"
                    onPress={() => {
                      resetForm();
                      setEditingProcessId(null);
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button intent="primary" size="small" type="submit">
                    {t("Save data")}
                    {isPending ? <CircularProgress size="24px" /> : <LockOpenOutlined />}
                  </Button>
                </>
              ) : (
                <Button
                  intent="tertiary"
                  size="small"
                  isDisabled={isEditingOtherForm}
                  onPress={() => setEditingProcessId(process.id)}
                >
                  {t("Edit data")}
                  <LockOutlined />
                </Button>
              )}
            </div>
            <div className="relative -mt-10 flex flex-col gap-4">
              <div className="grid grid-cols-[1fr_2fr] gap-10">
                <div className="space-y-2">
                  <Label14>{t("Production output")}</Label14>
                  <Text14>
                    {t("Add production output for all products from this process for {{ year }}", {
                      year: activeYear,
                    })}
                  </Text14>
                </div>
                <div className="max-w-sm">
                  <JSONSchemaFieldConnected
                    isRequired
                    isDisabled={!isEditing}
                    fieldName="production_output"
                    schema={process.input_output_schema}
                    fieldConfig={{
                      ...process.input_output_schema.properties.production_output,
                      unit: formatUnit(process.production_output_unit),
                      title: `${t("Total Production Output")} ${activeYear ?? ""}`,
                    }}
                  />
                </div>
              </div>
              {Object.entries(groupedFieldConfigs).map(([groupKey, subgroup]) => {
                const { label, isOutput } = groupDisplay[groupKey as ElementType];
                return (
                  <Fragment key={groupKey}>
                    <hr />
                    <div className="grid grid-cols-[1fr_2fr] gap-10">
                      <div className="space-y-2">
                        <Label14>{label}</Label14>
                        <Text14>
                          {t("Add all {{ inOrOut }} related to {{ label }} for {{ year }}", {
                            inOrOut: isOutput ? t("outputs") : t("inputs"),
                            year: activeYear,
                            label,
                          })}
                        </Text14>
                      </div>
                      <div className="flex flex-col gap-6">
                        {Object.entries(subgroup).map(([subgroupKey, items]) => {
                          return (
                            <div className="flex flex-col gap-2" key={subgroupKey}>
                              {subgroupKey !== "" && <Label12>{subgroupKey}</Label12>}
                              <div className="grid xl:grid-cols-3 grid-cols-2 gap-6">
                                {items!.map(([fieldname, fieldConfig]) => {
                                  const formFieldName = `input_output.${fieldname}`;

                                  return (
                                    <JSONSchemaFieldConnected
                                      // @ts-expect-error TODO: Fix this weird type issue, similar to https://github.com/orgs/react-hook-form/discussions/9789
                                      control={methods.control}
                                      key={formFieldName}
                                      schema={process.input_output_schema}
                                      fieldSchema={process.input_output_schema.$defs.InputOutput}
                                      fieldName={formFieldName}
                                      fieldConfig={fieldConfig}
                                      isDisabled={!isEditing}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Fragment>
                );
              })}
              {scopedPackagingProducts.length > 0 && (
                <>
                  <hr />
                  <div className="grid grid-cols-[1fr_2fr] gap-10">
                    <div className="space-y-2">
                      <Label14>
                        {t("Packaging")} {activeYear}
                      </Label14>
                      <Text14>
                        {t("Add packaging for all products from this process for {{ year }}", {
                          year: activeYear,
                        })}
                      </Text14>
                    </div>
                    <div className="grid xl:grid-cols-3 grid-col-2 gap-6">
                      {scopedPackagingProducts.map((packaging) => (
                        <NumberFieldConnected<ProductionProcessFields>
                          key={packaging.id}
                          name={`packaging.${packaging.id}`}
                          minValue={0}
                          label={packaging.name}
                          inputProps={
                            packaging.unit
                              ? {
                                  addonRight: formatUnit(packaging.unit),
                                }
                              : undefined
                          }
                          isDisabled={!isEditing}
                        />
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Card>
      </form>
    </FormProvider>
  );
};

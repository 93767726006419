import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { useGetElementaries, useGetEndsOfScale } from "../api/endpoints/elementaries";
import { Elementary } from "../api/types";

const useElementariesQuery = () => {
  const getElementaries = useGetElementaries();

  return useSuspenseQuery({
    queryKey: ["elementaries"],
    queryFn: getElementaries,
    select: (elementaries) => {
      return {
        elementaries,
        elementariesMap: Object.fromEntries(elementaries.map((item) => [item.id, item])),
      };
    },
  });
};

export const useElementaries = () => {
  const {
    data: { elementaries, elementariesMap },
  } = useElementariesQuery();

  return { elementaries, elementariesMap };
};

export const useEndsOfScale = ({ elementaryId }: { elementaryId?: Elementary["id"] }) => {
  const getEndsOfScale = useGetEndsOfScale();

  return useQuery({
    queryKey: ["elementaries", elementaryId, "endsOfScale"],
    queryFn: () => (elementaryId ? getEndsOfScale(elementaryId) : []),
    enabled: !!elementaryId,
  });
};

import tw from "tailwind-styled-components";

const Container = tw("div")`
  relative
  py-2
  px-6
  w-full
  flex items-center justify-end gap-8
  text-xs
  text-gray-400
`;

export const Footer = () => {
  return (
    <div className="fixed bottom-0 right-0">
      <Container>
        <span>© Emidat GmbH 2024</span>
      </Container>
    </div>
  );
};

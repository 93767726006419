import { animated, config, useSpring, useTransition } from "@react-spring/web";
import { useEffect } from "react";
import logo from "../assets/logo.png";
import { useBanner } from "../state/banner";

export const Banner = () => {
  const { showBanner, setShowBanner } = useBanner();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowBanner(false);
    }, 1200);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [setShowBanner]);

  const fade = useSpring({
    from: { opacity: 0, transform: "translateY(-10px) scale(1.2)" },
    to: { opacity: 1, transform: "translateY(0) scale(1)" },
    config: config.gentle,
  });

  const fadeOutOnUnmountTransitions = useTransition(showBanner, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 200 },
  });

  return fadeOutOnUnmountTransitions(
    (styles, item) =>
      item && (
        <animated.div
          style={styles}
          className="fixed flex items-center justify-center bg-white top-0 left-0 w-screen h-screen z-50"
        >
          <animated.img style={fade} className="h-10" src={logo} />
        </animated.div>
      ),
  );
};

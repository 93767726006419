import { ProgramOperator } from "../api/types";

import epdonline from "../assets/publication-imgs/epd-online.png";
import epdNorge from "../assets/verification-imgs/epd-norge.jpg";
import ibu from "../assets/verification-imgs/ibu.png";
import inies from "../assets/verification-imgs/inies.png";

export const EPD_NORGE_ID = "d50c2dcb-a44d-4a93-be3b-5a7b77d74c75";

export const HARD_CODED_PROGRAM_OPERATION_IMAGES: Record<ProgramOperator["id"], string> = {
  "5edc63b3-6789-4f12-a1cd-d1b0c5c1abe3": ibu,
  [EPD_NORGE_ID]: epdNorge,
  "7abfee13-f51d-44de-8243-4276618b0cbc": epdonline,
  "f84c6a79-c0a5-431b-861e-9e4c27d00dc8": inies,
};

import { Article } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { EPDSearchResult } from "../../../../api/types";
import { IconContainer } from "../../../../components/IconContainer";
import { InfoTooltip } from "../../../../components/InfoTooltip";
import { Link } from "../../../../components/Link";
import { Radio } from "../../../../components/RadioGroupField";
import { Label } from "../../../../components/Typography";
import { RadioGroupFieldConnected } from "../../../../form-components/RadioGroupFieldConnected";

export const PrimaryDataItem = ({ item }: { item: EPDSearchResult }) => {
  const { t } = useTranslation();

  return (
    <label
      htmlFor={`field-${item.id}`}
      key={item.id}
      className="flex gap-4 items-center max-w-full"
    >
      <IconContainer $active $blue className="shrink-0">
        <Article />
      </IconContainer>
      <div className="flex flex-col flex-1">
        <Link
          intent="link"
          size="small"
          href={item.item_source_url || item.epd_pdf_url}
          target="_blank"
        >
          {item.name_en}
        </Link>
        <p className="text-sm line-clamp-1 shrink-0">
          {item.manufacturer}, {item.reference_year}
        </p>
      </div>
      <RadioGroupFieldConnected name="epdId" aria-label={t("Select")}>
        <Radio value={item.id} isDisabled={!item.can_be_used} alignment="end">
          {t("Select")}
        </Radio>
      </RadioGroupFieldConnected>
    </label>
  );
};

export const PrimaryData = ({ primaryData }: { primaryData: EPDSearchResult[] }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2">
      <Label className="flex items-center gap-2">
        {t("EPD data")}
        <InfoTooltip>
          {t(`EPD data is the life cycle inventory (LCI) data provided by published EPDs.`)}
        </InfoTooltip>
      </Label>
      {primaryData.map((item) => (
        <PrimaryDataItem key={item.id} item={item} />
      ))}
    </div>
  );
};

import { ComponentProps } from "react";
import { Controller, ControllerProps, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ComboBoxField } from "../components/ComboBoxField";

export const ComboBoxFieldConnected = <TFieldValues extends object>({
  name,
  isRequired,
  rules,
  options,
  creatable,
  ...props
}: Pick<ControllerProps<TFieldValues>, "name" | "rules"> &
  ComponentProps<typeof ComboBoxField>) => {
  const { t } = useTranslation();
  const { control } = useFormContext<TFieldValues>();
  const usesNumberIds = options.length > 0 && typeof options[0].id === "number";

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired ? t("Required") : undefined,
        ...rules,
      }}
      render={({ field: { value, onChange, disabled, ...field }, fieldState: { error } }) => (
        <ComboBoxField
          {...field}
          value={
            options.find((opt) => (usesNumberIds ? Number(opt.id) === value : opt.id === value)) ||
            (creatable ? { id: value, label: value } : null)
          }
          onChange={(_, opt) => {
            if (opt?.id) {
              const id = usesNumberIds ? Number(opt.id) : opt.id;
              onChange(id);
            }
          }}
          creatable={creatable}
          options={options}
          errorMessage={error?.message}
          isRequired={isRequired}
          placeholder={t("Select …")}
          {...props}
        />
      )}
    />
  );
};

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { NumberFieldConnected } from "../../form-components/NumberFieldConnected";
import { ConcreteScenario } from "./ProductSpecsConcreteScenario";

export type DrainageChannelScenario = ConcreteScenario & {
  pavement: ReturnType<typeof usePavementOptions>[number]["id"];
  installation_type: ReturnType<typeof useInstallationTypeOptions>[number]["id"];
  installation_class: ReturnType<typeof useInstallationClassOptions>[number]["id"];
  concrete_installation_m3: number;
  steel_reinforcement_installation_kg: number;
  bitumen_seal_installation_kg: number;
};

const usePavementOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { label: t("Concrete"), id: "CONCRETE" as const },
      { label: t("Asphalt"), id: "ASPHALT" as const },
      { label: t("Plaster"), id: "PLASTER" as const },
    ],
    [t],
  );
};

const useInstallationTypeOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { label: t("Type I"), id: "I" as const },
      { label: t("Type M"), id: "M" as const },
    ],
    [t],
  );
};

const useInstallationClassOptions = () => {
  return useMemo(
    () => ["A 15", "B 125", "C 250", "D 400", "E 600", "F 900"].map((x) => ({ label: x, id: x })),
    [],
  );
};

export const DraingeChannelInstallationFields = () => {
  const { t } = useTranslation();

  return (
    <>
      <ComboBoxFieldConnected
        name="scenario.pavement"
        label={t("Pavement")}
        isRequired
        options={usePavementOptions()}
      />
      <ComboBoxFieldConnected
        name="scenario.installation_type"
        label={t("Installation Type")}
        isRequired
        options={useInstallationTypeOptions()}
      />
      <ComboBoxFieldConnected
        name="scenario.installation_class"
        label={t("Installation Class")}
        isRequired
        options={useInstallationClassOptions()}
      />
      <NumberFieldConnected
        name="scenario.concrete_installation_m3"
        minValue={0}
        label={t("Concrete (at installation)")}
        isRequired
        inputProps={{
          addonRight: "m³",
        }}
      />
      <NumberFieldConnected
        name="scenario.steel_reinforcement_installation_kg"
        minValue={0}
        label={t("Reinforcement steel (at installation)")}
        isRequired
        inputProps={{
          addonRight: "kg",
        }}
      />
      <NumberFieldConnected
        name="scenario.bitumen_seal_installation_kg"
        minValue={0}
        label={t("Bitumen sealing (at installation)")}
        isRequired
        inputProps={{
          addonRight: "kg",
        }}
      />
    </>
  );
};

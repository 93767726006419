import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";

const LAZY_DELAY = 2000;

export const Loading = ({ lazy }: { lazy?: boolean }) => {
  const [show, setShow] = useState(lazy ? false : true);

  useEffect(() => {
    if (show) return;

    const timeout = setTimeout(() => setShow(true), LAZY_DELAY);

    return () => clearTimeout(timeout);
  }, [show, lazy]);

  if (!show) return null;

  return (
    <div className="w-full h-full grid items-center justify-center">
      <CircularProgress style={{ color: "var(--steelblue)" }} />
    </div>
  );
};

import { cva } from "class-variance-authority";
import { memo } from "react";
import { Link } from "react-router";
import { NavbarItemType } from "../config/useNavbarItems";
import { useGetLinkWithParams } from "../url/useGetLinkWithParams";
import { IconContainer } from "./IconContainer";

export const itemContainerStyles = cva(
  [
    "flex",
    "items-center",
    "gap-5",
    "py-3",
    "px-4",
    "font-bold",
    "text-builtgreen",
    "cursor-pointer",
    "hover:bg-neutral-200",
    "active:bg-neutral-200",
    "group",
  ],
  {
    variants: {
      collapsed: {
        true: ["pl-4", "justify-end"],
      },
    },
    defaultVariants: {
      collapsed: false,
    },
  },
);

const isSomeChildActive = (item: NavbarItemType, activePath: string) => {
  return item.children?.some((child) => activePath.startsWith(child.path));
};

const isActiveChild = (item: NavbarItemType, activePath: string) => {
  return activePath.startsWith(item.path + "/");
};

const isHomescreenWithOpenProductDetail = (item: NavbarItemType, activePath: string) => {
  return item.path === "/" && activePath.startsWith("/products/");
};

export const NavbarItem = memo(
  ({
    item,
    activePath,
    collapsed,
    pale,
    noBorder,
  }: {
    item: NavbarItemType;
    activePath: string;
    collapsed?: boolean;
    pale?: boolean;
    noBorder?: boolean;
  }) => {
    const getLinkWithParams = useGetLinkWithParams();
    const active =
      activePath === item.path ||
      isActiveChild(item, activePath) ||
      isSomeChildActive(item, activePath) ||
      isHomescreenWithOpenProductDetail(item, activePath);

    return (
      <Link to={getLinkWithParams(item.path)}>
        <div className={itemContainerStyles({ collapsed })}>
          <IconContainer $active={active} $pale={pale} $bordered={pale && !noBorder}>
            {item.icon}
          </IconContainer>
          {!collapsed && <div>{item.name}</div>}
        </div>
      </Link>
    );
  },
);

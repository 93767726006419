import { useTranslation } from "react-i18next";
import { Label, Text16 } from "../components/Typography";

export const ManageProfile = () => {
  const { t } = useTranslation();

  return (
    <div className="pb-8 space-y-4">
      <div className="flex flex-col gap-3">
        <Label>{t("Reset password")}</Label>
        <Text16>
          {t(
            "To reset your password, please log out and click on the 'Forgot password?' link on the login page.",
          )}
        </Text16>
      </div>
    </div>
  );
};

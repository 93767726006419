import { CheckCircle, RadioButtonUncheckedOutlined } from "@mui/icons-material";
import { cva, VariantProps } from "class-variance-authority";
import { ComponentProps, forwardRef, ReactNode } from "react";
import {
  CheckboxGroup,
  CheckboxGroupProps,
  Checkbox as CheckboxPrimitive,
  CheckboxProps,
} from "react-aria-components";
import { FieldLabel } from "./FieldLabel";
import { FieldText } from "./FieldText";

export const CheckboxGroupField = forwardRef<
  HTMLDivElement,
  Omit<CheckboxGroupProps, "children" | "className"> & {
    children?: ReactNode;
    label?: string;
    labelProps?: ComponentProps<typeof FieldLabel>;
    errorMessage?: string;
  }
>(({ label, errorMessage, labelProps, children, ...props }, ref) => {
  return (
    <CheckboxGroup {...props} className="flex flex-col" ref={ref}>
      {label && (
        <FieldLabel isRequired={props.isRequired} isDisabled={props.isDisabled} {...labelProps}>
          {label}
        </FieldLabel>
      )}
      {children}
      {errorMessage && <FieldText intent="error">{errorMessage}</FieldText>}
    </CheckboxGroup>
  );
});

const checkboxStyles = cva(["flex", "items-center", "gap-2", "py-2"], {
  variants: {
    isDisabled: {
      true: "opacity-50 cursor-not-allowed",
      false: "cursor-pointer",
    },
    alignment: {
      start: "flex-row",
      end: "flex-row-reverse",
    },
  },
});

export const Checkbox = ({
  children,
  alignment = "start",
  ...props
}: Omit<CheckboxProps, "className"> & {
  children?: ReactNode;
} & VariantProps<typeof checkboxStyles>) => {
  return (
    <CheckboxPrimitive
      {...props}
      className={checkboxStyles({ isDisabled: props.isDisabled, alignment })}
    >
      {({ isSelected }) => (
        <>
          {!isSelected ? (
            <RadioButtonUncheckedOutlined className="text-builtgreen" />
          ) : (
            <CheckCircle className="text-steelblue" />
          )}
          <span className="text-base font-bold">{children}</span>
        </>
      )}
    </CheckboxPrimitive>
  );
};

import { useSuspenseQuery } from "@tanstack/react-query";
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { useGetPlants } from "../api/endpoints/plants";
import { useActiveManufacturer } from "./manufacturers";

const initialState = {
  activePlant: undefined,
  activePlantId: undefined,
  setActivePlantId: () => {},
};

const Context = createContext<ReturnType<typeof useContextValue>>(initialState);

const useContextValue = () => {
  const [activePlantIdStored, setActivePlantIdStored] = useLocalStorage<string>("activePlantId");
  const [activePlantId, setActivePlantId] = useState(activePlantIdStored);
  useEffect(() => {
    setActivePlantIdStored(activePlantId);
  }, [activePlantId, setActivePlantIdStored]);

  const { data: plants } = usePlants();

  const activePlant = useMemo(() => {
    if (plants.length === 0) return undefined;

    const found = plants.find(({ id }) => id === activePlantId);

    if (found) return found;

    if (plants.length > 0) {
      setActivePlantId(plants[0].id);
      return plants[0];
    }
  }, [plants, activePlantId]);

  return {
    activePlant,
    activePlantId,
    setActivePlantId,
  };
};

export const ActivePlantProvider = ({ children }: { children: ReactNode }) => (
  <Context.Provider value={useContextValue()}>{children}</Context.Provider>
);

export const useActivePlantControl = () => {
  return useContext(Context);
};

export const NoPlantError = class extends Error {
  constructor() {
    super("No active plant");
  }
};

export const useActivePlant = () => {
  const { activePlant } = useContext(Context);

  if (!activePlant) {
    throw new NoPlantError();
  }

  return activePlant;
};

export const usePlants = () => {
  const { activeManufacturer } = useActiveManufacturer();
  const getPlants = useGetPlants();

  return useSuspenseQuery({
    queryFn: () => getPlants({ manufacturerId: activeManufacturer.id }),
    queryKey: [activeManufacturer.id, "plants"],
  });
};

import { ClearOutlined, WindowOutlined } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BulkUploadResult } from "../api/types";
import { Button } from "../components/Button";
import { DataGrid } from "../components/DataGrid";
import { Link } from "../components/Link";
import { PageContainer } from "../components/PageContainer";
import { TopBar } from "../components/TopBar";
import { Heading4, Text16 } from "../components/Typography";
import { DownloadMaterialTemplate } from "../page-components/bulk-upload/DownloadMaterialTemplate";
import { DownloadProductsTemplate } from "../page-components/bulk-upload/DownloadProductTemplate";
import { UploadFile } from "../page-components/bulk-upload/UploadFile";
import { useBulkUploadDomain } from "../page-components/bulk-upload/useBulkUploadDomain";

export const BulkUpload = () => {
  const { t } = useTranslation();

  const [errors, setErrors] = useState<BulkUploadResult["errors"] | null>();

  const { navigateBackLink, type } = useBulkUploadDomain();

  return (
    <PageContainer>
      <TopBar
        icon={<WindowOutlined />}
        title={type === "materials" ? t("Upload new materials") : t("Upload new products")}
        input={
          <Link intent="tertiaryFlat" href={navigateBackLink}>
            {t("Cancel")}
            <ClearOutlined />
          </Link>
        }
      />

      {errors ? (
        <ShowErrors errors={errors} goBack={() => setErrors(null)} />
      ) : (
        <TemplateDownloadFileUpload setErrors={setErrors} />
      )}
    </PageContainer>
  );
};

const PlaceholderTable = () => {
  return (
    <div className="grid grid-cols-[2fr_1fr_1fr] gap-4 w-full">
      {[...Array(3)].map((_, column) => (
        <div key={column} className="flex flex-col gap-1">
          <div className="bg-builtgreen h-6"></div>
          {[...Array(8)].map((_, row) => (
            <div key={row} className="bg-neutral-200 h-6"></div>
          ))}
        </div>
      ))}
    </div>
  );
};

const TemplateDownloadFileUpload = ({
  setErrors,
}: {
  setErrors: Dispatch<SetStateAction<BulkUploadResult["errors"] | null | undefined>>;
}) => {
  const { t } = useTranslation();
  const { type } = useBulkUploadDomain();

  return (
    <div className="flex flex-grow items-center justify-center">
      <div className="grid gap-20 max-w-6xl grid-cols-[1fr_1px_1fr]">
        <StepContainer
          heading={t("Step 1: Download Template")}
          description={t("Fill your supplier material details in our Excel Template")}
        >
          <div className="border border-concrete p-8">
            <div className="flex flex-col justify-between items-center gap-8">
              <PlaceholderTable />
              {type === "materials" && <DownloadMaterialTemplate />}
              {type === "products" && <DownloadProductsTemplate />}
            </div>
          </div>
        </StepContainer>
        <div className="w-px bg-concrete" />
        <StepContainer
          heading={t("Step 2: Upload Template")}
          description={t("Upload the filled Excel file and let us do the rest")}
        >
          <UploadFile setErrors={setErrors} />
        </StepContainer>
      </div>
    </div>
  );
};

const ShowErrors = ({
  errors,
  goBack,
}: {
  errors: BulkUploadResult["errors"];
  goBack: () => void;
}) => {
  const { t } = useTranslation();

  const getRow = (input: BulkUploadResult["errors"][0]) => {
    return {
      id: input.row,
      row: input.row,
      name: input.row_name,
      error: input.message,
    };
  };

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: "row",
        headerName: t("Row number"),
        width: 150,
      },
      {
        field: "name",
        headerName: t("Name"),
        width: 200,
      },
      {
        field: "error",
        headerName: t("Error type"),
        width: 600,
      },
    ];
  }, [t]);
  return (
    <div className="flex flex-col gap-4 items-center p-12">
      <Heading4>{t("{{ n }} erros identified", { n: errors.length })}</Heading4>
      <div className="flex flex-col  items-center">
        <Text16>{t("We could not add your items due to errors.")}</Text16>
        <Text16>{t("Please review below and upload the sheet again.")}</Text16>
      </div>

      <DataGrid rows={errors.map(getRow)} columns={columns} disableRowSelectionOnClick />
      <div className="fixed bottom-10 flex items-center justify-center gap-4">
        <Button intent="secondary" onPress={goBack}>
          {t("Previous step")}
        </Button>
      </div>
    </div>
  );
};

const StepContainer = ({
  children,
  heading,
  description,
}: {
  heading: string;
  description: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="grid grid-rows-[auto_1fr] gap-4 w-full h-full">
      <div className="flex flex-col gap-2 items-center">
        <Heading4 className="text-center">{heading}</Heading4>
        <Text16 className="text-center">{description}</Text16>
      </div>
      {children}
    </div>
  );
};

import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";

export const EditFlowNav = ({
  nextSubmit,
  prevLabel,
  nextLabel,
  onPrev,
  onNext,
  nextDisabled,
  nextLoading,
}: {
  nextSubmit?: boolean;
  prevLabel?: string;
  nextLabel?: string;
  onNext?: () => void;
  onPrev?: () => void;
  nextDisabled?: boolean;
  nextLoading?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div className="absolute left-1/2 -translate-x-1/2 bottom-10 flex items-center justify-center gap-6">
      <Button type="button" intent="secondary" onPress={onPrev}>
        {prevLabel || t("Back")}
      </Button>
      <Button
        onPress={onNext}
        type={nextSubmit ? "submit" : "button"}
        isDisabled={nextDisabled || nextLoading}
      >
        {nextLabel || t("Next")}
        {nextLoading && <CircularProgress size="20px" />}
      </Button>
    </div>
  );
};

import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Product } from "../../api/types";
import { useRemoveProducts } from "../../state/products";
import { showSuccessToast } from "../../util/toasts";

export const useProductBulkDeletion = ({
  selectedProducts,
}: {
  selectedProducts: Set<Product>;
}) => {
  const { t } = useTranslation();
  const { mutateAsync: removeProducts } = useRemoveProducts();

  const onDeleteSelectedProducts = useCallback(async () => {
    await removeProducts(
      [...selectedProducts].map((product) => ({
        manufacturerId: product.manufacturer_id,
        plantId: product.plant_id,
        productId: product.id,
      })),
    );

    showSuccessToast(t("Products deleted successfully"));
  }, [removeProducts, selectedProducts, t]);

  return {
    onDeleteSelectedProducts,
  };
};

import { DownloadOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { useDownloadMaterialsBulkUploadSheet } from "../../state/materials";

export const DownloadMaterialTemplate = () => {
  const { t } = useTranslation();
  const { mutate: download, isPending: downloadLoading } = useDownloadMaterialsBulkUploadSheet();

  return (
    <Button onPress={() => download()}>
      {downloadLoading ? <CircularProgress size="24px" /> : <DownloadOutlined />}
      {t("Download template")}
    </Button>
  );
};

import { useLocation } from "react-router";

export const useBulkUploadDomain = () => {
  const activePath = useLocation().pathname;
  const last = activePath.split("/").reverse()[0];
  const isMaterial = last === "materials";
  const isProduct = last === "products";

  if (isMaterial)
    return {
      navigateBackLink: "/suppliers-and-materials",
      type: "materials",
    };
  else if (isProduct)
    return {
      navigateBackLink: "/products",
      type: "products",
    };
  else return {};
};

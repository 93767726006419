import { useSuspenseQuery } from "@tanstack/react-query";
import { useGetProductionProcessTemplates } from "../api/endpoints/production-process-templates";

export const useProductionProcessTemplates = () => {
  const getProductionProcessTemplates = useGetProductionProcessTemplates();

  return useSuspenseQuery({
    queryFn: getProductionProcessTemplates,
    queryKey: ["production-process-templates"],
  });
};

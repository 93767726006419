import { ResponsivePie } from "@nivo/pie";
import { ElectricityMixForm } from "./ElectricityMix";

export const ElectricityMixPreview = ({
  labels,
  colors,
  values,
}: {
  labels: { [key: string]: string };
  colors: { [key: string]: string };
  values: ElectricityMixForm;
}) => {
  const data = Object.entries(values).map(([key, value]) => ({
    id: key,
    label: labels[key],
    value,
    color: colors[key],
  }));

  return (
    <ResponsivePie
      data={data}
      margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
      colors={data.map((d) => d.color)}
      innerRadius={0.75}
      padAngle={3}
      cornerRadius={0}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      enableArcLabels={false}
      // arcLabelsSkipAngle={10}
      // arcLabelsTextColor={{
      //   from: "color",
      //   modifiers: [["darker", 2]],
      // }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
    />
  );
};

import { RadioButtonCheckedOutlined, RadioButtonUncheckedOutlined } from "@mui/icons-material";
import { cva, VariantProps } from "class-variance-authority";
import { ComponentProps, forwardRef, ReactNode } from "react";
import {
  RadioGroup,
  RadioGroupProps,
  Radio as RadioPrimitive,
  RadioProps,
} from "react-aria-components";
import { FieldLabel } from "./FieldLabel";
import { FieldText } from "./FieldText";

export const RadioGroupField = forwardRef<
  HTMLDivElement,
  Omit<RadioGroupProps, "children" | "className"> & {
    children?: ReactNode;
    label?: string;
    labelProps?: ComponentProps<typeof FieldLabel>;
    errorMessage?: string;
  }
>(({ label, errorMessage, labelProps, children, ...props }, ref) => {
  return (
    <RadioGroup {...props} className="flex flex-col" ref={ref}>
      {label && (
        <FieldLabel isRequired={props.isRequired} isDisabled={props.isDisabled} {...labelProps}>
          {label}
        </FieldLabel>
      )}
      {children}
      {errorMessage && <FieldText intent="error">{errorMessage}</FieldText>}
    </RadioGroup>
  );
});

const radioStyles = cva(["flex", "items-center", "gap-2", "py-2"], {
  variants: {
    isDisabled: {
      true: "opacity-50 cursor-not-allowed",
      false: "cursor-pointer",
    },
    alignment: {
      start: "flex-row",
      end: "flex-row-reverse",
    },
  },
});

export const Radio = ({
  children,
  alignment = "start",
  ...props
}: Omit<RadioProps, "className"> & {
  children?: ReactNode;
} & VariantProps<typeof radioStyles>) => {
  return (
    <RadioPrimitive {...props} className={radioStyles({ isDisabled: props.isDisabled, alignment })}>
      {({ isSelected }) => (
        <>
          {isSelected ? (
            <RadioButtonCheckedOutlined className="text-steelblue" />
          ) : (
            <RadioButtonUncheckedOutlined className="text-builtgreen" />
          )}
          <span className="text-base font-bold">{children}</span>
        </>
      )}
    </RadioPrimitive>
  );
};

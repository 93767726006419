import { ComponentProps } from "react";
import { Controller, ControllerProps, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TextAreaField } from "../components/TextAreaField";

export const TextAreaFieldConnected = <TFieldValues extends object>({
  name,
  isRequired,
  rules,
  ...props
}: Pick<ControllerProps<TFieldValues>, "name" | "rules"> &
  ComponentProps<typeof TextAreaField>) => {
  const { t } = useTranslation();
  const { control } = useFormContext<TFieldValues>();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired ? t("Required") : undefined,
        ...rules,
      }}
      render={({ field: { value, disabled, ...field }, fieldState: { error } }) => (
        <TextAreaField
          {...field}
          {...props}
          value={value || ""}
          errorMessage={error?.message}
          isRequired={isRequired}
        />
      )}
    />
  );
};

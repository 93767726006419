import { TextArea as TextAreaPrimitive, TextAreaProps } from "react-aria-components";

import { cva } from "class-variance-authority";
import { forwardRef } from "react";

const styles = cva(
  [
    "flex",
    "px-3",
    "py-2",
    "rounded",
    "outline-none",
    "w-full",
    "border",
    "border-neutral-300 focus:border-builtgreen",
    "bg-white",
    "text-builtgreen",
    "font-normal",
    "disabled:opacity-50",
  ],
  {
    variants: {
      hasError: {
        true: "border-red-500 focus:border-red-500",
      },
      isDisabled: {
        true: "cursor-not-allowed opacity-50 border-transparent",
      },
    },
  },
);

export const TextArea = forwardRef<
  HTMLTextAreaElement,
  Omit<TextAreaProps, "className"> & {
    isDisabled?: boolean;
    hasError?: boolean;
  }
>(({ isDisabled, hasError, ...props }, ref) => {
  return (
    <TextAreaPrimitive ref={ref} rows={6} {...props} className={styles({ isDisabled, hasError })} />
  );
});

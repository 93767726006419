import {
  DataGridPro as DataGridPrimitive,
  GridColumnMenu,
  GridColumnMenuProps,
} from "@mui/x-data-grid-pro";

import { ComponentProps } from "react";

type NativeProps = ComponentProps<typeof DataGridPrimitive>;
type Props = Omit<NativeProps, "rows"> &
  Required<Pick<NativeProps, "rows">> & {
    defaultCursorOnHover?: boolean;
  };

export const DataGrid = (props: Props) => {
  const hasPagination = props.rows.length > 50;

  return (
    <DataGridPrimitive
      {...props}
      pagination={hasPagination}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 50,
          },
        },
        ...props.initialState,
      }}
      slots={{
        columnMenu: CustomColumnMenu,
      }}
      pageSizeOptions={hasPagination ? [10, 20, 50, 100, 500] : [50]}
      className="h-full bg-white"
      rowHeight={48}
      autoHeight
      columnHeaderHeight={64}
      sx={{
        fontSize: 16,
        borderRadius: 4,
        fontWeight: "normal",
        overflow: "hidden",
        color: "var(--builtgreen)",
        fontFamily: "CircularStd",
        "& .MuiDataGrid-main": {
          overflow: "scroll",
        },
        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader, .MuiDataGrid-filler": {
          backgroundColor: "#F5F5F5",
          textTransform: "uppercase",
          fontSize: 14,
          color: "#737373",
          fontWeight: "bold",
          fontFamily: "CircularStd",
          padding: "0 10px 0 24px",
        },
        "& .MuiDataGrid-cell": {
          padding: "0 10px 0 24px",
        },
        "& .MuiDataGrid-row:hover": {
          cursor: props.defaultCursorOnHover ? "default" : "pointer",
        },
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
          display: "none",
        },
      }}
    />
  );
};

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuSortItem: null,
      }}
    />
  );
}

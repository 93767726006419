import { useCallback } from "react";
import { Elementary, EndOfScale } from "../types";
import { useApi } from "../useApi";

export const useGetElementaries = () => {
  const api = useApi<Elementary[]>();

  return useCallback(() => api({ url: `/v1/elementaries` }), [api]);
};

export const useGetEndsOfScale = () => {
  const api = useApi<EndOfScale[]>();

  return useCallback(
    (id: Elementary["id"]) => api({ url: `/v1/elementaries/${id}/indicators-ends-of-scale` }),
    [api],
  );
};

import { useMemo } from "react";
import { Declaration, Product } from "../api/types";
import { exists } from "../util/commonUtil";

export const useFilteredDisplayDocuments = ({
  documents,
  declaration,
  isAdminMode,
  activeOperatorId,
}: {
  documents: Product["documents"];
  declaration: Declaration | null;
  isAdminMode: boolean;
  activeOperatorId?: string;
}) => {
  const POs = useMemo(
    () => [...new Set(documents.map((doc) => doc.program_operator_id).filter(exists))],
    [documents],
  );

  return useMemo(
    () =>
      documents.filter((doc) => {
        // If the document is already in the declaration, don't show here.
        const declarationDocs = declaration?.result_documents.map((x) => x.document.id) || [];
        if (declarationDocs.includes(doc.document.id)) return false;
        // Don't show emidat_only documents unless we are in admin mdoe
        else if (!isAdminMode && doc.document.emidat_only) return false;
        // If the document is not assigned to any program operator, show it.
        else if (!doc.program_operator_id) return true;
        // For all other documents, filter by program operator.
        const onlyShowThisPo = declaration
          ? declaration.program_operator_id
          : activeOperatorId || POs[0];
        return doc.program_operator_id === onlyShowThisPo;
      }),
    [documents, declaration, isAdminMode, activeOperatorId, POs],
  );
};

import { LicenseInfo } from "@mui/x-license";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { AppRouter } from "./AppRouter";
import { BASE_URL } from "./api/useApi";
import "./index.css";
import "./localization/i18next";

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY);

const environment = import.meta.env.VITE_ENVIRONMENT;

// TODO: double check whether that's necessary debug info
// eslint-disable-next-line no-console
console.log(environment);

if (environment === "production") {
  Sentry.init({
    dsn: "https://c7209d8147ef926e3fef8a522beca67f@o4507385458196480.ingest.de.sentry.io/4507389599023184",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", BASE_URL],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>,
);

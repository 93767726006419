import { ajvResolver } from "@hookform/resolvers/ajv";
import { isAxiosError } from "axios";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router";
import { DynamicFormJSONSchema, Product } from "../../api/types";
import { Heading3, Text16 } from "../../components/Typography";
import { JSONSchemaFieldConnected } from "../../form-components/JSONSchemaFieldConnected";
import { NumberFieldConnected } from "../../form-components/NumberFieldConnected";
import { useElementaries } from "../../state/elementaries";
import {
  HARD_CODED_BAGGED_CONCRETE_ELEMENTARY_ID,
  HARD_CODED_BITUMEN_MEMBRANE_ELEMENTARY_ID,
  HARD_CODED_DRAINAGE_CHANNEL_ELEMENTARY_ID,
  HARD_CODED_INSITU_CONCRETE_CATEGORY_ID,
  HARD_CODED_PRECAST_CATEGORY_ID,
} from "../../state/HARD_CODED";
import { useProduct, useSelectedProductId, useUpdateProduct } from "../../state/products";
import { useGetLinkWithParams } from "../../url/useGetLinkWithParams";
import { exists } from "../../util/commonUtil";
import { showErrorToast } from "../../util/toasts";
import { useWarnBeforeUnload } from "../../util/useWarnBeforeUnload";
import { EditFlowNav } from "./EditFlowNav";
import {
  BaggedConcreteInstallationFields,
  BaggedConcreteScenario,
} from "./ProductSpecsBaggedConcreteScenario";
import { BitumenScenario, BitumenWeldingField } from "./ProductSpecsBitumenScenario";
import { ConcreteApplicationField, ConcreteScenario } from "./ProductSpecsConcreteScenario";
import {
  DrainageChannelScenario,
  DraingeChannelInstallationFields,
} from "./ProductSpecsDraingeChannelScenario";

type Scenarios =
  | DrainageChannelScenario
  | ConcreteScenario
  | BitumenScenario
  | BaggedConcreteScenario
  | null;

interface ProductUsageForm {
  transportDistance: number;
  scenario: Scenarios;
}

const ProductSpecsMain = ({ id }: { id: string }) => {
  const { data: selectedProduct } = useProduct(id);

  return <ProductSpecsForm selectedProduct={selectedProduct} />;
};

export const ProductSpecs = () => {
  const id = useSelectedProductId();

  if (!id) return null;

  return <ProductSpecsMain id={id} />;
};

const ProductSpecsForm = ({ selectedProduct }: { selectedProduct: Product }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getLinkWithParams = useGetLinkWithParams();
  const { elementariesMap } = useElementaries();
  const onPrev = () => navigate(getLinkWithParams("/edit/product-details"));

  const { mutate: updateProduct, isPending: loading } = useUpdateProduct();
  const [searchParams] = useSearchParams();

  const isConcrete = useMemo(() => {
    const categoryId = elementariesMap[selectedProduct.elementary_id]?.product_category_id;
    if (!categoryId) return false;
    return [HARD_CODED_INSITU_CONCRETE_CATEGORY_ID, HARD_CODED_PRECAST_CATEGORY_ID].includes(
      categoryId,
    );
  }, [selectedProduct.elementary_id, elementariesMap]);

  const isBaggedConcrete =
    selectedProduct.elementary_id === HARD_CODED_BAGGED_CONCRETE_ELEMENTARY_ID;

  const isDrainageChannel =
    selectedProduct.elementary_id === HARD_CODED_DRAINAGE_CHANNEL_ELEMENTARY_ID;

  const isBitumenMembrane =
    selectedProduct.elementary_id === HARD_CODED_BITUMEN_MEMBRANE_ELEMENTARY_ID;

  const hasScenario = isConcrete || isBitumenMembrane;

  const methods1 = useForm<Record<string, unknown>>({
    // @ts-expect-error TODO: Try and fix https://ajv.js.org/guide/typescript.html#utility-types-for-schemas
    resolver: ajvResolver(selectedProduct.tech_specs_schema, {
      strict: false,
    }),
  });
  const reset = methods1.reset;

  const methods2 = useForm<ProductUsageForm>({
    defaultValues: selectedProduct
      ? {
          transportDistance: selectedProduct.distance_a4_km ?? NaN,
          scenario: hasScenario ? selectedProduct.scenario : null,
        }
      : undefined,
  });

  useWarnBeforeUnload(methods1.formState.isDirty || methods2.formState.isDirty);

  const onSubmit = async () => {
    if (!selectedProduct) return;

    [methods1, methods2].forEach((method) => method.clearErrors());

    const formsValid = (await Promise.all([methods1.trigger(), methods2.trigger()])).every(Boolean);

    if (!formsValid) return;

    updateProduct(
      {
        productId: selectedProduct.id,
        product: {
          ...selectedProduct,
          tech_specs: Object.fromEntries(
            Object.entries(methods1.getValues()).filter(([, value]) => exists(value)),
          ),
          distance_a4_km: selectedProduct.metadata.declared_modules.a4
            ? methods2.getValues("transportDistance")
            : undefined,
          scenario: hasScenario ? methods2.getValues("scenario") : undefined,
          production_process_id: selectedProduct.production_process_id,
        },
      },
      {
        onSuccess: () => {
          navigate({
            pathname: "/edit/product-production-process",
            search: searchParams.toString(),
          });
        },
        onError: (error) => {
          if (isAxiosError(error) && error.response?.data?.message) {
            // Apparently we have a technical error message from the server here
            // that's user-friendly enough to display it
            showErrorToast(error.response?.data?.message);
          } else {
            console.error(error);
          }
        },
      },
    );
  };

  useEffect(
    function populateForm() {
      if (!selectedProduct || !selectedProduct.tech_specs) return;

      reset(selectedProduct.tech_specs);
    },
    [selectedProduct, reset],
  );

  return (
    <>
      <div className="flex flex-col h-full pb-20">
        <div className="flex-grow">
          <div className="flex flex-col gap-8">
            <FormProvider {...methods1}>
              <form noValidate>
                <TechSpecsFields schema={selectedProduct.tech_specs_schema} />
              </form>
            </FormProvider>

            {selectedProduct.metadata.declared_modules.a4 && (
              <>
                <div className="mx-auto w-full max-w-6xl space-y-4">
                  <div className="flex flex-col gap-2">
                    <Heading3>{t("Product usage")}</Heading3>
                    <Text16>
                      {t(
                        "We use the following information to build scenarios for your product's environmental data after leaving the factory gate.",
                      )}
                    </Text16>
                  </div>
                  <div className="flex-grow">
                    <FormProvider {...methods2}>
                      <form noValidate className="grid xl:grid-cols-3 grid-cols-2 gap-6">
                        {isBaggedConcrete && <BaggedConcreteInstallationFields />}
                        {isDrainageChannel && <DraingeChannelInstallationFields />}
                        {isConcrete && <ConcreteApplicationField />}
                        {isBitumenMembrane && <BitumenWeldingField />}
                        <NumberFieldConnected
                          name="transportDistance"
                          minValue={0}
                          label={t("Average Distance to Customer")}
                          isRequired
                          inputProps={{
                            addonRight: "km",
                          }}
                        />
                      </form>
                    </FormProvider>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <EditFlowNav onPrev={onPrev} onNext={onSubmit} nextSubmit={false} nextLoading={loading} />
      </div>
    </>
  );
};

const TechSpecsFields = ({ schema }: { schema: DynamicFormJSONSchema }) => {
  return (
    <div className="flex-grow flex flex-col gap-10 py-2 mx-auto w-full max-w-6xl">
      <div className="grid xl:grid-cols-3 grid-cols-2 gap-6">
        {Object.entries(schema.properties).map(([fieldname, fieldConfig]) => (
          <JSONSchemaFieldConnected
            key={fieldname}
            schema={schema}
            fieldName={fieldname}
            fieldConfig={fieldConfig}
          />
        ))}
      </div>
    </div>
  );
};

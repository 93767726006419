import { useEffect, useState } from "react";
import {
  useDeletePrechainProduct,
  useGetPrechainProduct,
} from "../api/endpoints/prechain-products";
import { PrechainProduct } from "../api/types";
import { useActiveManufacturer } from "./manufacturers";
import { useActivePlant } from "./plants";

export const usePrechainProduct = (id: string) => {
  const { activeManufacturer } = useActiveManufacturer();
  const activePlant = useActivePlant();

  const getPrechainProduct = useGetPrechainProduct();

  const [prechainProduct, setPrechainProduct] = useState<PrechainProduct | null>(null);

  useEffect(() => {
    if (!activePlant) return;

    getPrechainProduct({
      manufacturerId: activeManufacturer.id,
      plantId: activePlant.id,
      prechainProductId: id,
    }).then(setPrechainProduct);
  }, [activeManufacturer, activePlant, getPrechainProduct, id]);

  return prechainProduct;
};

export const useDeletePrechainProductById = () => {
  const { activeManufacturer } = useActiveManufacturer();
  const activePlant = useActivePlant();

  const deletePrechainProduct = useDeletePrechainProduct();

  return (id: string) => {
    if (!activePlant) return;

    return deletePrechainProduct({
      manufacturerId: activeManufacturer.id,
      plantId: activePlant.id,
      prechainProductId: id,
    });
  };
};

import { CheckCircle, RadioButtonUncheckedOutlined } from "@mui/icons-material";
import { forwardRef, ReactNode } from "react";
import { Checkbox, CheckboxProps } from "react-aria-components";
import { Label14 } from "./Typography";

/**
 * A checkbox with a label, using react-aria's Checkbox component
 */
export const CheckboxField = forwardRef<
  HTMLLabelElement,
  Omit<CheckboxProps, "className" | "children"> & { children: ReactNode }
>(({ children, ...props }, ref) => {
  return (
    <Checkbox ref={ref} className="flex items-center gap-2 cursor-pointer" {...props}>
      {({ isSelected }) => (
        <>
          {!isSelected ? (
            <RadioButtonUncheckedOutlined className="text-builtgreen" />
          ) : (
            <CheckCircle className="text-steelblue" />
          )}
          <Label14>{children}</Label14>
        </>
      )}
    </Checkbox>
  );
});

import { ArticleOutlined } from "@mui/icons-material";
import { cva } from "class-variance-authority";
import { ReactNode, useRef } from "react";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { IconContainer } from "./IconContainer";

const zoneStyles = cva(
  ["flex", "items-center", "justify-center", "p-8", "text-gray-600", "border-2"],
  {
    variants: {
      isOver: {
        true: "border-solid border-gray-600",
        false: "border-dashed border-concrete",
      },
    },
    defaultVariants: {
      isOver: false,
    },
  },
);

const dropzoneIllustrationStyles = cva([
  "absolute",
  "top-0",
  "left-0",
  "h-12",
  "w-12",
  "border",
  "border-dashed",
  "translate-x-1/3",
  "-translate-y-1/3",
])();

const DroppingIllustration = () => {
  return (
    <div className="relative mt-4">
      <div className={dropzoneIllustrationStyles} />
      <div className="relative">
        <IconContainer $green $active>
          {<ArticleOutlined />}
        </IconContainer>
      </div>
    </div>
  );
};

export const Dropzone = ({
  children,
  onDrop,
}: {
  children: ReactNode;
  onDrop?: (item: File) => void;
}) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    drop: (item: { files: File[] }) => onDrop?.(item.files[0]),
    canDrop: () => true,
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        hidden
        onChange={(e) => {
          if (e.target.files) {
            onDrop?.(e.target.files[0]);
          }
        }}
      />
      <div ref={drop} className={zoneStyles({ isOver })}>
        <div className="h-full w-full flex flex-col gap-4 items-center">
          <DroppingIllustration />
          {children}
        </div>
      </div>
    </>
  );
};

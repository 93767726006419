import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";

import { useUserData } from "../auth/auth";
import { Heading2, Text16 } from "../components/Typography";
import { UserBubble } from "../components/UserBubble";
import { EditUserDialog } from "../page-components/EditUserDialog";

const Content = tw.div`
  flex
  flex-col
  items-center
  justify-center
  gap-5
  w-full
  h-full
`;

export const UserProfile = () => {
  const { t } = useTranslation();
  const userData = useUserData();

  return (
    <div className="w-full h-full flex flex-col gap-3">
      <Heading2>{t("Profile Settings")}</Heading2>
      <Content>
        <div className="flex">
          <UserBubble className="w-20 h-20 text-3xl mr-5">
            <img src={userData?.picture} className="rounded-full" />
          </UserBubble>
          <div className="flex flex-col justify-center">
            <Text16>
              {t("Name")}: {userData?.user_metadata?.name}
            </Text16>
            <Text16>
              {t("Company")}: {userData?.user_metadata?.company}
            </Text16>
            <Text16>
              {t("Email")}: {userData?.email}
            </Text16>
          </div>
        </div>
        <EditUserDialog />
      </Content>
    </div>
  );
};

import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { showErrorToast } from "./toasts";

const MAX_FILE_SIZE = 2 * 1024 * 1024;

export function useValidateFileSize() {
  const { t } = useTranslation();

  return useCallback(
    (file: File) => {
      if (file.size > MAX_FILE_SIZE) {
        showErrorToast(
          t(
            "Sorry, we can only process files smaller than {{ size }} MB. Please try again with a smaller file.",
            { size: (MAX_FILE_SIZE / 1024 / 1024).toFixed(0) },
          ),
        );
        throw new Error("File too large");
      }
    },
    [t],
  );
}

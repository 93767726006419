import { FactoryOutlined } from "@mui/icons-material";
import { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { PageContainer } from "../components/PageContainer";
import { TopBar } from "../components/TopBar";
import { ElectricityMix } from "../page-components/plant-settings/ElectricityMix";
import { ProductionProcesses } from "../page-components/plant-settings/ProductionProcesses";
import { WasteTreatment } from "../page-components/plant-settings/WasteTreatment";
import { useActiveOnboardingStep } from "../state/onboarding";
import { useActivePlant } from "../state/plants";

export enum ProductionForm {
  ElectricityMix = "electricityMix",
  WasteTreatment = "wasteTreatment",
}

const ProductionPageContent = () => {
  const [editing, setEditing] = useState<ProductionForm>();
  const editingState = { editing, setEditing };

  const navigate = useNavigate();
  const { step: onboardingStep } = useActiveOnboardingStep();
  useEffect(() => {
    if (onboardingStep < 2) {
      navigate("/onboarding");
    }
  }, [onboardingStep, navigate]);

  return (
    <div className="flex flex-col gap-8 py-8">
      <ElectricityMix {...editingState} />
      <WasteTreatment {...editingState} />
      <Suspense>
        <ProductionProcesses />
      </Suspense>
    </div>
  );
};

export const Production = () => {
  const { t } = useTranslation();
  const activePlant = useActivePlant();

  return (
    <PageContainer>
      <TopBar icon={<FactoryOutlined />} title={t("Production")} subtitle={activePlant.name} />
      <Suspense>
        <ProductionPageContent />
      </Suspense>
    </PageContainer>
  );
};

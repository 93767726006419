import { CloseOutlined, CompareOutlined, DeleteOutlined } from "@mui/icons-material";
import { ReactNode, useMemo } from "react";
import { DialogTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Product } from "../../api/types";
import { Button } from "../../components/Button";
import { CheckboxField } from "../../components/CheckboxField";
import { areProductsComparable } from "../../lib/product";
import { DeleteProductsModal } from "./DeleteProductsModal";
import { useProductBulkDeletion } from "./useProductBulkDeletion";
import type { useProductSelection } from "./useProductSelection";

export const MultiSelectActionBar = ({
  selectedProducts,
  products,
  isSelectMode,
  toggleSelectMode,
  toggleSelectAll,
  resetSelectedProducts,
  extraRightSideContent,
}: {
  products: Product[];
  extraRightSideContent?: ReactNode;
} & Pick<
  ReturnType<typeof useProductSelection>,
  | "selectedProducts"
  | "isSelectMode"
  | "toggleSelectMode"
  | "toggleSelectAll"
  | "resetSelectedProducts"
>) => {
  const { t } = useTranslation();
  const { onDeleteSelectedProducts } = useProductBulkDeletion({ selectedProducts });

  const navigate = useNavigate();
  const productsAreComparable = useMemo(() => {
    if (!isSelectMode || selectedProducts.size !== 2) return false;

    const [product1, product2] = selectedProducts;

    return areProductsComparable(product1, product2);
  }, [isSelectMode, selectedProducts]);

  return (
    <div className="flex items-center justify-between gap-4">
      <div className="flex items-center gap-4 h-10">
        <CheckboxField onChange={toggleSelectMode} isSelected={isSelectMode}>
          {isSelectMode
            ? t("{{ count }} selected", { count: selectedProducts.size })
            : t("Select multiple products")}
        </CheckboxField>
        {isSelectMode && (
          <Button intent="tertiary" size="small" onPress={toggleSelectAll}>
            {selectedProducts.size < products.length ? t("Select all") : t("Unselect all")}
          </Button>
        )}
      </div>
      <div className="flex items-center gap-2">
        {isSelectMode && selectedProducts.size > 0 && (
          <DialogTrigger>
            <Button intent="tertiaryFlat" size="small">
              {t("Delete {{ count }} products", { count: selectedProducts.size })}
              <DeleteOutlined />
            </Button>
            <DeleteProductsModal
              selectedProducts={selectedProducts}
              onSubmit={async (close) => {
                await onDeleteSelectedProducts();
                close();
                resetSelectedProducts();
                toggleSelectMode();
              }}
            />
          </DialogTrigger>
        )}
        {isSelectMode && productsAreComparable && (
          <Button
            intent="tertiaryFlat"
            size="small"
            onPress={() => {
              const [product1, product2] = selectedProducts;
              navigate(`/products/${product1.id}/compare/${product2.id}`);
            }}
          >
            {t("Compare")}
            <CompareOutlined />
          </Button>
        )}
        {isSelectMode && (
          <Button intent="tertiaryFlat" size="small" onPress={toggleSelectMode}>
            {t("Cancel")}
            <CloseOutlined />
          </Button>
        )}
        {extraRightSideContent}
      </div>
    </div>
  );
};

import { cva } from "class-variance-authority";
import tw from "tailwind-styled-components";
import { Label12, Label16 } from "./Typography";

const Bubble = tw("span")`block w-3 h-3 rounded-full shrink-0`;

const ReadyBubble = tw(Bubble)`bg-steelblue`;
const WaitingBubble = tw(Bubble)`bg-amber-500`;
const MissingBubble = tw(Bubble)`border border-dotted border-neutral-500`;
const DoneBubble = tw(Bubble)`border border-builtgreen bg-energyyellow`;

const styles = cva("", {
  variants: {
    missing: {
      true: "text-neutral-400",
    },
    ready: {
      true: "text-steelblue",
    },
    truncate: {
      true: "truncate",
    },
  },
});

export const StatusIndicator = ({
  small,
  text,
  missing,
  ready,
  waiting,
  done,
  truncate,
}: {
  small?: boolean;
  text: string;
  missing?: boolean;
  waiting?: boolean;
  ready?: boolean;
  done?: boolean;
  truncate?: boolean;
}) => {
  const labelStyles = styles({ missing, ready, truncate });
  return (
    <div className={`flex items-center gap-2 ${truncate ? "overflow-hidden" : "shrink-0"}`}>
      {ready && <ReadyBubble />}
      {waiting && <WaitingBubble />}
      {missing && <MissingBubble />}
      {done && <DoneBubble />}
      {small ? (
        <Label12 className={labelStyles}>{text}</Label12>
      ) : (
        <Label16 className={labelStyles}>{text}</Label16>
      )}
    </div>
  );
};

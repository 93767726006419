import { AddOutlined, FactoryOutlined } from "@mui/icons-material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useLocation, useNavigate } from "react-router";
import { useMaxPlantsCount } from "../auth/auth";
import { useActivePlantControl, usePlants } from "../state/plants";
import { IconContainer } from "./IconContainer";
import { itemContainerStyles } from "./NavbarItem";
import { Text14 } from "./Typography";

const maybeNavigateBackOneLayer = (pathname: string, navigate: NavigateFunction) => {
  const pathParts = pathname.split("/");
  if (pathParts.length > 2) {
    navigate(pathParts.slice(0, 2).join("/"));
  }
};

const pagesThatRedirectToRoot = [/\/products\/.+$/, /\/manage-plants\/.+$/];
const useMaybeNavigateBack = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(() => {
    if (pagesThatRedirectToRoot.some((page) => page.test(location.pathname))) {
      navigate("/");
    } else {
      maybeNavigateBackOneLayer(location.pathname, navigate);
    }
  }, [location.pathname, navigate]);
};

export const PlantSelector = ({
  collapsed,
  onClose,
}: {
  collapsed: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { data: plants } = usePlants();
  const { activePlant, setActivePlantId } = useActivePlantControl();

  const navigate = useNavigate();
  const goToNewPlant = useCallback(() => {
    navigate("/manage-plants/new");
  }, [navigate]);

  const maxPlants = useMaxPlantsCount();

  const maybeNavigateBack = useMaybeNavigateBack();

  if (plants.length === 0) return null;

  const maxPlantsReached = plants.length >= maxPlants;

  const sortedPlants = plants.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      {sortedPlants.map((item) => (
        <div
          key={item.id}
          className={itemContainerStyles({ collapsed })}
          onClick={() => {
            if (item.id !== activePlant?.id) {
              maybeNavigateBack();
              setActivePlantId(item.id);
            }
            onClose();
          }}
        >
          <IconContainer $active={item.id === activePlant?.id}>
            <FactoryOutlined />
          </IconContainer>
          {!collapsed && <div>{item.name}</div>}
        </div>
      ))}
      <div
        className={itemContainerStyles({ collapsed })}
        onClick={maxPlantsReached ? undefined : goToNewPlant}
      >
        <IconContainer $pale>
          <AddOutlined />
        </IconContainer>
        {!collapsed && t("Add new plant")}
      </div>
      {!collapsed && maxPlantsReached && (
        <Text14 className="px-4 text-neutral-400">
          {t("You have reached the maximum number of allowed plants.")}
        </Text14>
      )}
    </>
  );
};

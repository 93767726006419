import { ErrorOutlined, LightbulbOutlined, WarningOutlined } from "@mui/icons-material";
import { cva, VariantProps } from "class-variance-authority";
import { ReactNode } from "react";

const alertStyles = cva(["pl-4", "pr-6", "py-3", "text-sm", "w-fit"], {
  variants: {
    intent: {
      info: "bg-yellow-100 text-builtgreen",
      warning: "bg-yellow-500 text-white",
      error: "bg-red-500 text-white",
    },
  },
});

export const Alert = ({
  header,
  children,
  intent,
}: VariantProps<typeof alertStyles> & {
  header?: ReactNode;
  children?: ReactNode;
}) => {
  return (
    <div className={alertStyles({ intent })}>
      <div className="grid grid-cols-[24px_auto] gap-2">
        <div>
          {intent === "info" && <LightbulbOutlined className="text-builtgreen" />}
          {intent === "warning" && <WarningOutlined className="text-white" />}
          {intent === "error" && <ErrorOutlined className="text-white" />}
        </div>
        <div className="space-y-1 mt-0.5">
          {header && <div className="font-medium">{header}</div>}
          {children}
        </div>
      </div>
    </div>
  );
};

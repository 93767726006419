import { useMemo } from "react";

export const usePeriodUnderReviewYearOptions = () => {
  return useMemo(() => {
    const lastCompletedYear = new Date().getFullYear() - 1;

    const optionsThreeYearsAgo = Array.from(
      { length: 3 },
      (_, i) => lastCompletedYear - i,
    ).reverse();

    return optionsThreeYearsAgo.map((year) => ({ label: year.toString(), id: year }));
  }, []);
};

import { HeartBroken, RefreshOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export const ServerNotReachable = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex flex-col gap-3 h-screen items-center justify-center">
      <div className="max-w-xl flex flex-col gap-3 items-center text-center">
        <HeartBroken className="text-gray-300" sx={{ fontSize: 100 }} />
        <h2 className="text-xl">{t("We can't reach the server.")}</h2>
        <p className="text-lg flex flex-col gap-8 text-left">
          <span>
            {t(
              "This happens when your firewall settings don't allow reaching our server, or when our servers are offline.",
            )}
          </span>
          <span>
            {t(
              "Please double check your connection settings and your firewall and refresh this page.",
            )}
          </span>
          <span>
            {t("If this issue persists, please reach out to us at ")}
            <a className="underline" href="mailto:support@emidat.com">
              support@emidat.com
            </a>
            .
          </span>
        </p>
        <button
          className="mt-3 flex gap-3 items-center text-xl px-5 py-2 text-neutral-500 font-bold"
          onClick={() => window.location.reload()}
        >
          <RefreshOutlined />
          {t("Refresh")}
        </button>
      </div>
    </div>
  );
};

import { ajvResolver } from "@hookform/resolvers/ajv";
import { LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePatchPlant } from "../../api/endpoints/plants";
import { DynamicFormJSONSchema, Plant } from "../../api/types";
import { Button } from "../../components/Button";
import { Card } from "../../components/Card";
import { JSONSchemaFieldConnected } from "../../form-components/JSONSchemaFieldConnected";
import { ProductionForm } from "../../pages/Production";
import { useActivePlant } from "../../state/plants";
import { showSuccessToast } from "../../util/toasts";
import { useWarnBeforeUnload } from "../../util/useWarnBeforeUnload";

export const WasteTreatment = ({
  ...props
}: {
  editing?: ProductionForm;
  setEditing: (value?: ProductionForm) => void;
}) => {
  const { t } = useTranslation();
  const activePlant = useActivePlant();

  const { mutate: patchPlant, isPending } = usePatchPlant();

  const onSubmit: SubmitHandler<Record<string, string>> = (fields) => {
    if (!activePlant) return;
    patchPlant(
      {
        plantId: activePlant.id,
        plant: {
          ...activePlant,
          waste_treatment: fields,
        },
      },
      {
        onSuccess: () => {
          showSuccessToast(t("Successfully updated waste treatment settings"));
        },
      },
    );
  };

  return (
    <WasteTreatmentForm
      activePlant={activePlant}
      loading={isPending}
      onSubmit={onSubmit}
      {...props}
    />
  );
};

const WasteTreatmentForm = ({
  activePlant,
  loading,
  onSubmit,
  editing,
  setEditing,
}: {
  activePlant: Plant;
  loading: boolean;
  onSubmit: SubmitHandler<Record<string, string>>;
  editing?: ProductionForm;
  setEditing: (value?: ProductionForm) => void;
}) => {
  const isEditMode = editing === ProductionForm.WasteTreatment;
  const isEditingOtherForm = editing && !isEditMode;

  const { t } = useTranslation();

  const methods = useForm<Record<string, string>>({
    // @ts-expect-error TODO: Try and fix https://ajv.js.org/guide/typescript.html#utility-types-for-schemas
    resolver: ajvResolver(activePlant.waste_treatment_schema, { strict: false }),
    defaultValues: activePlant.waste_treatment ?? {},
  });

  useWarnBeforeUnload(isEditMode && methods.formState.isDirty);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit((values) => {
          onSubmit(values);
          setEditing(undefined);
        })}
        noValidate
      >
        <Card
          title={t("Waste Treatment")}
          input={
            isEditMode ? (
              <>
                <Button
                  intent="tertiary"
                  size="small"
                  type="button"
                  onPress={() => {
                    methods.reset(activePlant.waste_treatment);
                    setEditing(undefined);
                  }}
                >
                  {t("Cancel")}
                </Button>
                <Button intent="primary" size="small" type="submit">
                  {t("Save data")}
                  {loading ? <CircularProgress size="24px" /> : <LockOpenOutlined />}
                </Button>
              </>
            ) : (
              <Button
                intent="tertiary"
                size="small"
                type="button"
                isDisabled={isEditingOtherForm}
                onPress={() => setEditing(ProductionForm.WasteTreatment)}
              >
                {t("Edit data")}
                <LockOutlined />
              </Button>
            )
          }
        >
          <div className="space-y-4">
            <WasteTreatmentFields
              schema={activePlant.waste_treatment_schema}
              isEditMode={isEditMode}
            />
          </div>
        </Card>
      </form>
    </FormProvider>
  );
};

const WasteTreatmentFields = ({
  schema,
  isEditMode,
}: {
  schema: DynamicFormJSONSchema;
  isEditMode?: boolean;
}) => {
  return (
    <div className="flex-grow pt-3">
      <div className="grid xl:grid-cols-4 grid-cols-3 gap-8">
        {Object.entries(schema.properties).map(([fieldname, fieldConfig]) => (
          <JSONSchemaFieldConnected
            key={fieldname}
            schema={schema}
            isDisabled={!isEditMode}
            fieldName={fieldname}
            fieldConfig={fieldConfig}
          />
        ))}
      </div>
    </div>
  );
};

import { compareAsc, compareDesc } from "date-fns";
import { memo, useMemo } from "react";
import tw from "tailwind-styled-components";
import { Product } from "../../api/types";
import { ProductCard } from "./ProductCard";
import { useProductSorting } from "./useProductSorting";

const Content = tw("div")`
  grid
  gap-5
  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6
`;

const statusOrder: Record<Product["status"], number> = {
  incomplete: 0,
  draft: 1,
  "under-review": 2,
  completed: 3,
};

const compareCreatedAt = (a: Product, b: Product, direction: "asc" | "desc") => {
  return direction === "desc"
    ? compareDesc(new Date(a.created_at), new Date(b.created_at))
    : compareAsc(new Date(a.created_at), new Date(b.created_at));
};

const compareGwpTotal = (a: Product, b: Product, direction: "asc" | "desc") => {
  const aGwp =
    a.lca_results?.impact_summary?.a1_a3.find((i) => i.indicator_name === "GWP-total")?.value || 0;
  const bGwp =
    b.lca_results?.impact_summary?.a1_a3.find((i) => i.indicator_name === "GWP-total")?.value || 0;
  return direction === "desc" ? bGwp - aGwp : aGwp - bGwp;
};

const compareProducts = (
  a: Product,
  b: Product,
  sort: ReturnType<typeof useProductSorting>["sort"],
) => {
  switch (sort.by) {
    case "created_at":
      return compareCreatedAt(a, b, sort.direction);

    case "name":
      return sort.direction === "desc"
        ? b.name.localeCompare(a.name)
        : a.name.localeCompare(b.name);

    case "gwpTotal":
      return compareGwpTotal(a, b, sort.direction);

    case "status":
      return statusOrder[b.status] - statusOrder[a.status];

    case "material":
      return sort.direction === "desc"
        ? b.material.localeCompare(a.material)
        : a.material.localeCompare(b.material);

    default:
      return 0;
  }
};

export const ProductsTileView = memo(function ProductsTileViewComponent({
  products,
  searchInput,
  isSelectMode,
  selectedProducts,
  toggleSelect,
  sort,
}: {
  products: Product[];
  searchInput: string;
  isSelectMode: boolean;
  selectedProducts: Set<Product>;
  toggleSelect: (product: Product) => void;
  sort: ReturnType<typeof useProductSorting>["sort"];
}) {
  const sortedProducts = useMemo(
    () => [...products].sort((a, b) => compareProducts(a, b, sort)),
    [products, sort],
  );

  const filteredProducts = useMemo(() => {
    return sortedProducts.filter((product) =>
      product.name.toLowerCase().includes(searchInput.toLowerCase()),
    );
  }, [sortedProducts, searchInput]);

  return (
    <Content>
      {filteredProducts.map((item) => (
        <ProductCard
          key={item.id}
          item={item}
          isSelectMode={isSelectMode}
          isSelected={selectedProducts.has(item)}
          onToggleSelect={toggleSelect}
        />
      ))}
    </Content>
  );
});

import { Radio } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ProductMetadata } from "../../api/types";
import { InfoTooltip } from "../../components/InfoTooltip";
import { Label12, Text16 } from "../../components/Typography";
import { HARD_CODED_PROGRAM_OPERATION_IMAGES } from "../../lib/programOperator";

export const SelectProgramOperatorForm = ({
  poWithAvailability: programOperatorAvailabilities,
  activeOperatorId,
  setActiveOperatorId,
}: {
  poWithAvailability: ProductMetadata["po_availability"];
  activeOperatorId: string | null;
  setActiveOperatorId: (value: string) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="space-y-4">
      <Label12 className="flex items-center gap-2">
        {t("Choose your program operator")}
        <InfoTooltip>
          {t(
            "An EPD Program Operator (PO) is an independent organization that administers the process of developing, verifying, and publishing Environmental Product Declarations. POs play a critical role in the environmental assessment landscape by ensuring that EPDs are accurate, standardized, and publicly available, thereby promoting sustainable product development and informed decision-making.",
          )}
        </InfoTooltip>
      </Label12>

      <div className="flex flex-col divide-y divide-neutral-300 border-y border-neutral-300">
        {programOperatorAvailabilities.map((option) => (
          <div
            className="grid grid-cols-[auto_1fr_auto] items-center gap-4 cursor-pointer py-4"
            key={option.po.id}
            onClick={(e) => {
              e.stopPropagation();
              setActiveOperatorId(option.po.id);
            }}
          >
            <div className="flex items-center justify-center overflow-hidden h-12 w-12 border">
              <img
                className={`w-full h-full object-contain ${!option.available && "opacity-30"}`}
                src={HARD_CODED_PROGRAM_OPERATION_IMAGES[option.po.id]}
              />
            </div>
            <div>
              <Text16 className={`font-bold ${!option.available ? "opacity-30" : ""}`}>
                {option.po.name}
              </Text16>
            </div>
            <Radio
              checked={activeOperatorId === option.po.id}
              onChange={() => setActiveOperatorId(option.po.id)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";

export type BitumenScenario = {
  welding_method: ReturnType<typeof useBitumenWeldingOptions>[number]["id"];
  installation_method: ReturnType<typeof useBitumenInstalltionOptions>[number]["id"];
};

const useBitumenWeldingOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { label: t("Full-surface welding"), id: "FULL_SURFACE" as const },
      { label: t("Overlap welding"), id: "OVERLAP" as const },
    ],
    [t],
  );
};

const useBitumenInstalltionOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { label: t("Glued, polyurethane adhesive, one layer"), id: "GLUED_PU_1L" as const },
      { label: t("Glued, polyurethane adhesive, multi layer"), id: "GLUED_PU_ML" as const },
      { label: t("Glued, bonding agent, one layer"), id: "GLUED_BONDING_AGENT_1L" as const },
      { label: t("Glued, bonding agent, multi layer"), id: "GLUED_BONDING_AGENT_ML" as const },
      { label: t("Glued, bituminous adhesive, one layer"), id: "GLUED_BITUMINOUS_1L" as const },
      { label: t("Glued, bituminous adhesive, multi layer"), id: "GLUED_BITUMINOUS_ML" as const },
      { label: t("Glued, contact adhesive, one layer"), id: "GLUED_CONTACT_1L" as const },
      { label: t("Glued, contact adhesive, multi layer"), id: "GLUED_CONTACT_ML" as const },
      { label: t("Mechanically fastened, one layer"), id: "MECHANICALLY_FASTENED_1L" as const },
      { label: t("Mechanically fastened, multi layer"), id: "MECHANICALLY_FASTENED_ML" as const },
      { label: t("Loosely laid, one layer"), id: "LOOSELY_LAID_1L" as const },
      { label: t("Loosely laid, multi layer"), id: "LOOSELY_LAID_ML" as const },
    ],
    [t],
  );
};

export const BitumenWeldingField = () => {
  const { t } = useTranslation();

  return (
    <>
      <ComboBoxFieldConnected
        name="scenario.welding_method"
        label={t("Welding method")}
        isRequired
        options={useBitumenWeldingOptions()}
      />
      <ComboBoxFieldConnected
        name="scenario.installation_method"
        label={t("Installation method")}
        isRequired
        options={useBitumenInstalltionOptions()}
      />
    </>
  );
};

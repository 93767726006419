import { useAuth0 } from "@auth0/auth0-react";
import Intercom from "@intercom/messenger-js-sdk";

export const useIntercom = ({ hide_default_launcher }: { hide_default_launcher?: boolean }) => {
  const { user } = useAuth0();

  Intercom({
    app_id: "xoey0lpy",
    email: user?.email,
    id: user?.email,
    custom_launcher_selector: "#intercom_launcher",
    hide_default_launcher,
  });
};

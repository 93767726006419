import tw from "tailwind-styled-components";

const Circle = tw("div")`absolute w-6 h-6 rounded-full p-1 `;

export const ProgressIndicatorIcon = ({ done }: { done: number }) => {
  const arc = (degrees: number) => {
    const inner = "linear-gradient(#0000 0 0) content-box intersect";
    const outer = `conic-gradient(#000 ${degrees}deg, #0000 0)`;

    return [inner, outer].join(", ");
  };

  return (
    <div className="w-6 h-6 relative">
      <Circle className="bg-neutral-300" style={{ mask: arc(360) }} />
      <Circle className="bg-builtgreen" style={{ mask: arc(done * 360) }} />
    </div>
  );
};

import { Home, QuestionMark } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useRouteError } from "react-router";

export function NotFoundPage() {
  const { t } = useTranslation();
  const error = useRouteError();

  console.error(error);

  return (
    <div className="flex flex-col gap-3 h-screen items-center justify-center">
      <QuestionMark className="text-gray-300" sx={{ fontSize: 100 }} />
      <h2 className="text-xl">{t("This page does't exist")}</h2>
      <a className="flex gap-3 items-center text-xl px-5 py-2 text-neutral-500 font-bold" href="/">
        <Home />
        {t("Back to Home")}
      </a>
    </div>
  );
}

import { Close } from "@mui/icons-material";
import { cva, VariantProps } from "class-variance-authority";
import { ReactNode } from "react";
import {
  Dialog,
  DialogProps,
  ModalOverlay,
  ModalOverlayProps,
  Modal as ModalPrimitive,
} from "react-aria-components";
import { Button } from "./Button";
import { Heading4 } from "./Typography";

const modalOverlayStyles = cva([
  "fixed",
  "inset-0",
  "z-[100]",
  "flex",
  "items-start",
  "justify-center",
  "min-h-full",
  "overflow-y-auto",
  "bg-slate-900",
  "bg-opacity-60",
  "p-8",
  "text-center",
  "backdrop-blur-sm",
]);

const modalStyles = cva(["w-full", "rounded-lg", "border", "bg-white", "text-left", "shadow-2xl"], {
  variants: {
    size: {
      sm: "max-w-md",
      md: "max-w-lg",
      lg: "max-w-2xl",
      xl: "max-w-6xl",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

export const Modal = ({
  children,
  isOpen,
  onOpenChange,
  size,
}: Pick<DialogProps, "children"> &
  Pick<ModalOverlayProps, "isOpen" | "onOpenChange"> &
  VariantProps<typeof modalStyles>) => {
  return (
    <ModalOverlay className={modalOverlayStyles()} isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalPrimitive className={modalStyles({ size })}>
        <Dialog className="flex flex-col outline-none">{children}</Dialog>
      </ModalPrimitive>
    </ModalOverlay>
  );
};

export const ModalHeader = ({ title, onClose }: { title: string; onClose?: () => void }) => (
  <div className="flex items-center justify-between border-b p-6">
    <Heading4>{title}</Heading4>
    {onClose && (
      <Button intent="tertiaryFlat" onPress={onClose}>
        <Close />
      </Button>
    )}
  </div>
);

export const ModalBody = ({ children }: { children: ReactNode }) => (
  <div className="bg-white p-6">{children}</div>
);

export const ModalFooter = ({ children }: { children: ReactNode }) => (
  <div className="flex items-center gap-4 justify-end border-t p-6">{children}</div>
);

import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Text16 } from "../components/Typography";

export const VersionInfo = () => {
  const [isOpen, setIsOpen] = useState(false);
  useHotkeys("shift+?", () => setIsOpen((open) => !open));

  // this is statically replaced with vite define
  const frontendVersion = `__BUILD_TIMESTAMP__`.replace(/"/g, "");

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogTitle>{t("Version Info")}</DialogTitle>
      <DialogContent>
        <Text16>{frontendVersion}</Text16>
      </DialogContent>
    </Dialog>
  );
};

import { ComponentProps, forwardRef, ReactNode } from "react";
import { TextField as TextFieldPrimitive, type TextFieldProps } from "react-aria-components";

import { FieldLabel } from "./FieldLabel";
import { FieldText } from "./FieldText";
import { Input } from "./Input";
import { InputWithSubfields } from "./InputWithSubfields";

export const TextField = forwardRef<
  HTMLInputElement,
  Omit<TextFieldProps, "children" | "className"> & {
    label?: string;
    labelProps?: ComponentProps<typeof FieldLabel>;
    errorMessage?: string;
    inputProps?: Omit<ComponentProps<typeof Input>, "size">;
    placeholder?: string;
    size?: "medium" | "large";
    subfields?: ReactNode;
  }
>(({ label, errorMessage, inputProps, labelProps, size, subfields, ...props }, ref) => {
  return (
    <TextFieldPrimitive {...props} ref={ref}>
      {label && (
        <FieldLabel isRequired={props.isRequired} isDisabled={props.isDisabled} {...labelProps}>
          {label}
        </FieldLabel>
      )}
      {subfields ? (
        <InputWithSubfields
          {...inputProps}
          size={size}
          disabled={props.isDisabled}
          hasError={!!errorMessage}
          subfields={subfields}
        />
      ) : (
        <Input {...inputProps} size={size} disabled={props.isDisabled} hasError={!!errorMessage} />
      )}
      {errorMessage && <FieldText intent="error">{errorMessage}</FieldText>}
    </TextFieldPrimitive>
  );
});

import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useActiveManufacturer } from "../state/manufacturers";

const environment = import.meta.env.VITE_ENVIRONMENT;

const SCRIPT_SRC = "https://plausible.io/js/script.pageview-props.js";

export const usePlausibleTracking = () => {
  const { user } = useAuth0();
  const { activeManufacturer } = useActiveManufacturer();

  useEffect(() => {
    const shouldAddScript =
      environment === "production" && !!user && !user.email?.endsWith("@emidat.com");

    const existingScript = document.querySelector(`script[src="${SCRIPT_SRC}"]`);

    if (shouldAddScript && !existingScript) {
      const script = document.createElement("script");
      script.setAttribute("defer", "");
      script.setAttribute("data-domain", "app.emidat.com");
      script.setAttribute("event-manufacturer", activeManufacturer.name);
      script.setAttribute("event-userId", user?.sub ?? "");
      script.setAttribute("src", SCRIPT_SRC);
      document.head.appendChild(script);
    }

    return () => {
      document.querySelectorAll(`script[src="${SCRIPT_SRC}"]`).forEach((script) => {
        script.remove();
      });
    };
  }, [user, activeManufacturer.name]);
};
